import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import AuthRoutes from './auth/component';
import MainAppReduxComp from './app/index';
import { connect } from 'react-redux';

function App(props) {
    //this is the application entry point

    //possible to just redirect to the login page
    if (props.isAuthenticated === false) {
        return <AuthRoutes />
    }
    //at this point, session data should exist //session data should be checked elsewhere
    if (props.session_data.email_verified_at == null && !props.location.pathname.includes('verifymail')) {/*email not verified, so redirect to mail verification*/
        return <Redirect to='/verifymail' />
    }
    //email was verified but, there is no existent company
    if (props.session_data.email_verified_at != null && props.session_data.active_company == null && !props.location.pathname.includes('/company/new')) {/*no existing company, so redirect to business registration*/
        return <Redirect to='/company/new' />
    }
    return <MainAppReduxComp />
}

// connect the store for the company dispatches and state 
const rootAppStateToProps = (state) => ({
    isAuthenticated: state.authState.isAuthenticated,
    session_data: state.authState.user_obj,
})
const AppRootReduxComp = withRouter(connect(rootAppStateToProps)(App))

export default AppRootReduxComp;

