import Axios from "axios";
import { AxiosHeaders } from "../constants";
import { base_url } from "../constants/constants";
var moment = require("moment-timezone");

export const HandlePayReqInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "PAYREQ_INPUT_CHANGE", changedField: { [name]: value } };
};
export const setActivePayReq = (payload) => {
  return { type: "SET_ACTIVE_PAYREQ", payload };
};
export const HandlePayReqSwitchInput = (e) => {
  const { value } = e.target ? e.target : e;
  return { type: "PAYREQ_INPUT_CHANGE", changedField: { company_id: value } };
};

export const clearPayReqForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_PAYREQ_FORM" };
};

export const PayReqFetchSuccess = (payload, isPayReq = false) => {
  return { type: "FETCH_PAYREQ_SUCCESS", payload, isPayReq };
};
export const SetKey = (payload) => {
  return { type: "SET_API_KEY", payload };
};
export const PayReqAddSuccess = (new_record, update = false) => {
  if (update) {
    // toastr.success("Howry!", "PayReq  updated successfully");
  } else {
    // toastr.success("Howry!", "PayReq  added successfully");
  }

  return { type: "ADD_PAYREQ_SUCCESS", new_record };
};
export const PayReqDeleteSuccess = (msg, record_id) => {
  // toastr.success("Howry!", msg);
  return { type: "DELETE_PAYREQ_SUCCESS", record_id };
};
export const FetchSingleSuccess = (payload) => {
  return { type: "FETCH_SINGLE_PAY_SUCCESS", payload };
};

export const FetchSingleFail = (msg) => {
  return { type: "FETCH_SINGLE_PAY_FAIL", msg };
};
export const PayReqReqError = (msg) => {
  // toastr.error("Whoops!", msg);
  return { type: "PAYREQ_ERROR" };
};

export const ActivateMerchantPayReq = (payload) => {
  return { type: "ACTIVATE_MERCHANT_PAYREQ", active_company: payload };
};

export const editPayReq = (obj) => {
  return { type: "EDIT_PAYREQ", current_pay_req_obj: obj };
};

export const HandleIsCompProgress = (isProgress) => {
  return { type: "PAYREQ_IS_PROGRESS", isProgress };
};

export const HandleIsCoping = (isCoping) => {
  return { type: "PAYREQ_IS_COPING", isCoping };
};

export const HandleCopySuccess = (payload) => {
  return { type: "PAYREQ_COPY_SUCCESS", payload };
};

export const ActivatePayReq = (id) => (dispatch) => {
  const url = base_url("company/" + id + "/setactive");
  if (id) {
    Axios.put(url)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // dispatch(HandlePayReqFetch(id , true))
          // toastr.success("Success!", " company was changed successfully");
          // dispatch(HandleUserFetch())
          dispatch(ActivateMerchantPayReq(res.data));
        } else {
          // toastr.error("Oops!", "The company wasn't changed");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // toastr.error("Please check your network and try again");
        }
      });
  }
};
export const HandlePayReqSubmit =
  (form_data, url_id, e, toggle) => (dispatch) => {
    dispatch(HandleIsCompProgress(true));

    //time zone
    let new_dates = {
      start_time: moment(form_data.start_time)
        .tz(moment.tz.guess())
        .format("Y-MM-DDTH:mm:ssZ"),
      end_time: moment(form_data.end_time)
        .tz(moment.tz.guess())
        .format("Y-MM-DDTH:mm:ssZ"),
    };

    // Object.assign(form_data, new_dates);
    const url = form_data.id
      ? base_url("payment_request/" + form_data.id)
      : base_url("company_account/" + url_id + "/payment_request");

    const req_type = form_data.id ? Axios.put : Axios.post;
    req_type(url, { ...form_data, ...new_dates }, { headers: AxiosHeaders })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (!form_data.id) {
            toggle();
          }
          dispatch(clearPayReqForm(e));
          dispatch(PayReqAddSuccess(response?.data));
          dispatch(HandleIsCompProgress(false));
          dispatch(HandlePayReqFetch(url_id));
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // toastr.error("Please check your network and try again");
        }
        dispatch(HandleIsCompProgress(false));
      });
    return null;
  };
export const HandlePayReqDelete = (record_id, deleteToggle) => (dispatch) => {
  dispatch(HandleIsCompProgress(true));
  const url = base_url("payment_request/" + record_id);

  Axios.delete(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 201 || response.status === 204) {
        dispatch(PayReqDeleteSuccess(response.data.message, record_id));
        dispatch(HandleIsCompProgress(false));
        deleteToggle();
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
      dispatch(HandleIsCompProgress(false));
    });

  return null;
};

export const HandleSinglePayFetch = (record_id) => (dispatch) => {
  const url = base_url("payments/" + record_id);

  Axios.get(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(FetchSingleSuccess(response.data.data));
      }
    })
    .catch((err) => {
      dispatch(FetchSingleFail(err));
    });

  return null;
};

export const GenerateKey = (record_id) => (dispatch) => {
  const url = base_url("account/apikey/" + record_id);

  Axios.put(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 200) {
        dispatch(SetKey(response.data.apikey));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });

  return null;
};
export const HandlePayReqFetch =
  (url_id, isPayReq = false) =>
  (dispatch) => {
    const url = base_url("company_account/" + url_id + "/payment_request");
    Axios.get(url, { headers: AxiosHeaders })
      .then((response) => {
        if (response.status === 200) {
          if (typeof response.data === "object" && isPayReq === true) {
            dispatch(PayReqFetchSuccess(response.data, true));
          } else {
            if (
              window.location.href.includes("go_live") &&
              response.data.data.length === 1
            ) {
              dispatch(PayReqFetchSuccess(response.data));
            } else {
              dispatch(PayReqFetchSuccess(response.data));
            }
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // toastr.error("Please check your network and try again");
        }
      });

    return null;
  };
