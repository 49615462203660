import  dashboard_active_icon from './images/dashboard-icon.svg'
import  dashboard_icon from './images/dashboard-white.svg'
import products_icon from './images/products-icon.svg'
import products_active_icon from './images/products_active.svg'
import transaction_icon from './images/transactions-icon.svg'
import transaction_active_icon from './images/transactions-active.svg'
import documents_icon from './images/documents-icon.svg'
import documentS_active_icon from './images/documents-active.svg'
import sandbox_icon from './images/sandbox-icon.svg'
import sandbox_active_icon from './images/sandbox-active.svg'
import go_live_icon from './images/go-live-icon.svg'
import go_live_active_icon from './images/go-live-active.svg'
import faq_icon from './images/faq-icon.svg'
import faq_active_icon from './images/faq-active.svg'
import support from './images/support.svg'
import support_active from './images/support-active.svg'
import pdf from './images/pdf.svg'
import sente_dash from './images/sente_dash.svg'

export const Icons = {
    dashboard_active_icon,
    dashboard_icon,
    products_icon,
    products_active_icon,
    transaction_icon,
    transaction_active_icon,
    documents_icon,
    documentS_active_icon,
    sandbox_active_icon,
    sandbox_icon,
    faq_icon,
    faq_active_icon,
    go_live_icon,
    go_live_active_icon,
    support,support_active,
    pdf,sente_dash
}