import Axios from "axios";
// import { toastr } from "react-redux-toastr";
import { AxiosHeaders } from "../constants";
import { base_url } from "../constants/constants";

export const HandleCompanyDocumentInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return {
    type: "COMPANY_DOCUMENT_INPUT_CHANGE",
    changedField: { [name]: value },
  };
};

export const clearCompanyDocumentForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_COMPANY_DOCUMENT_FORM" };
};

export const CompanyDocumentFetchSuccess = (payload) => {
  return { type: "FETCH_COMPANY_DOCUMENT_SUCCESS", payload };
};
export const fetch_type_id = (id) => {
  return { type: "TYPE_ID", id };
};
export const FetchSubmittedCompany = (payload) => {
  return { type: "SUBMITTED_COMPANY_OBJ", payload };
};
export const FetchSubmittedDoc = (payload) => {
  return { type: "SUBMITTED_DOC", payload };
};

export const CompanyDocumentAddSuccess = (new_record, status) => {
  if (status === 200) {
    // toastr.success("Ok!", "Company Document Updated");
  } else {
    // toastr.success("Ok!", "Company Document Added");
  }

  return { type: "ADD_COMPANY_DOCUMENT_SUCCESS", new_record };
};
export const CompanyDocumentDeleteSuccess = (msg, record_id) => {
  // Print the message here
  // toastr.success("Ok!", msg);
  return { type: "DELETE_COMPANY_DOCUMENT_SUCCESS", record_id };
};
export const CompanyDocumentReqError = (message) => {
  // toastr.error("Error!", message);
  return { type: "COMPANY_DOCUMENT_ERROR" };
};
export const HandleIsProgress = (isProgress) => {
  return { type: "COMPANY_IS_PROGRESS", isProgress };
};

export const editCompanyDocument = (obj) => {
  return { type: "EDIT_COMPANY_DOCUMENT", current_obj: obj };
};

export const companyDocumentPreview = (obj) => {
  return { type: "PREVIEW_COMPANY_DOCUMENT", doc_data: obj };
};

export const HandleCompanyDocumentSubmit = (form_data) => (dispatch) => {
  const url = form_data.id
    ? base_url("document/" + form_data.id)
    : base_url("company/" + form_data.company_id + "/document");
  const req_type = Axios.post;
  dispatch(HandleIsProgress(true));
  const formData = new FormData();
  formData.append("document_type_id", form_data.document_type_id);
  formData.append("doc_file", form_data.doc_file);
  form_data.id && formData.append("_method", "PUT");
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  req_type(url, formData, config)
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(HandleIsProgress(false));
        dispatch(CompanyDocumentAddSuccess(response.data, response.status));
      }
    })
    .catch(function (error) {
      dispatch(HandleIsProgress(false));
    });
  return null;
};

export const HandleCompanyDocumentDelete = (record_id) => (dispatch) => {
  const url = base_url("document/" + record_id);
  Axios.delete(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(
          CompanyDocumentDeleteSuccess(response.data.message, record_id)
        );
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
  return null;
};

export const HandleCompanyDocumentFetch = (url_id) => (dispatch) => {
  const url = base_url("company/" + url_id + "/document");
  Axios.get(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 200) {
        dispatch(CompanyDocumentFetchSuccess(response.data));
      }
    })
    .catch((reason) => {
      console.log("Network/Server Error", reason);
    });
  return null;
};

export const HandleDocumentReview = (id) => (dispatch) => {
  const url = `${base_url}document/${id}`;
  Axios.get(url, { headers: AxiosHeaders }).then((response) => {
    if (response.status === 200) {
      dispatch(companyDocumentPreview(response.data));
    }
  });
};
