import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  HandleMerchantTicketFetch,
  HandleTicketFetchTypes,
} from "../../../actions/merchant_support_ticket_action";
import { AddMerchantTicket } from "./add_merchant_ticket";
import { MerchantTicketTable } from "./merchant_ticket_table";

export const MerchantTicket = ({current_merchant_ticket_obj,merchant,ticket_types,response, merchant_ticket_list,dispatch}) => {
  const [modal, setModal] = useState(false);
  const dataFetchedRef = useRef(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    merchant.id && dispatch(HandleMerchantTicketFetch(merchant.id));
    ticket_types.length || dispatch(HandleTicketFetchTypes());
  }, [dispatch,merchant.id,ticket_types.length]);
  return (
    <div className="content-box-header panel-heading table-container">
      <AddMerchantTicket {...{current_merchant_ticket_obj,merchant,ticket_types,modal,toggle,merchant_ticket_list:null}} />
      {typeof merchant_ticket_list !== "undefined" && (
        <MerchantTicketTable
          ticket_types = {ticket_types}
          merchant_ticket_list={merchant_ticket_list}
          toggle={toggle}
          response={response}
        />
      )}
    </div>
  );
};
// connect to the store for the merchant ticket dispatches and state
const mapMerchantTicketStateToProps = (state) => {
  return {
    current_merchant_ticket_obj: state.merchantTicketState.current_merchant_ticket_obj,
    merchant_ticket_list: state.merchantTicketState.merchant_ticket_list,
    response: state.merchantTicketState.responseView,
    ticket_types: state.merchantTicketState.ticket_types,
    merchant: state.authState.user_obj,
  };
};

export const FiredUpMerchantTicketComp = connect(mapMerchantTicketStateToProps)(MerchantTicket);
