import React from "react";

const Stepper = ({ currentStep }) => {
  const steps = [
    { name: "Details", value: 1 },
    { name: "Enter OTP", value: 2 },
    { name: "Confirmation", value: 3 },
  ];
  return (
    <div class="md-stepper-horizontal orange mb-3">
      {steps.map((step) => (
        <div
          className={step.value !== currentStep ? "md-step" : "md-step active"}
        >
          <div class="md-step-circle">
            {currentStep > step.value ? (
              <span>
                {" "}
                <img
                  width="15px"
                  height="15px"
                  src="images/check-icon.svg"
                  alt=""
                />
              </span>
            ) : (
              <span>{step.value}</span>
            )}
          </div>
          <div class="md-step-title">{step.name}</div>
          <div class="md-step-bar-left"></div>
          <div class="md-step-bar-right"></div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
