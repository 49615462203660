import React from "react";
import { Row, Col } from "reactstrap";

export const FAQ = () => {
  return (
    <Col md={10} sm={12} lg={12}>
      <div className="content-box-header panel-heading table-container">
        <Row>
          <Col lg={12} sm={12} md={12}>
            <span className="faq-title">Frequently Asked Questions</span>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col lg={5} md={5} sm={12}>
            <Col lg={12} sm={12} className="p-5">
              <img src="images/info.svg" height="15px" width="15px" alt="" />{" "}
              <span className="table-content">Table of contents</span>
              <ul className="nav" style={{ display: "list-item", margin: 10 }}>
                {/* API menu */}
                <li className="my-2">
                  <a className="api-item" href="/#">
                    General
                  </a>
                </li>
                <li className="my-2">
                  <a className="active-item" href="/#">
                    -<span className="ml-1">API</span>
                  </a>
                </li>
                <li className="my-2">
                  <a className="api-item" href="/#">
                    Products &amp; Services
                  </a>
                </li>
                <li className="my-2">
                  <a className="api-item" href="/#">
                    Transactions
                  </a>
                </li>
                <li className="my-2">
                  <a className="api-item" href="/#">
                    Payments
                  </a>
                </li>
                <li className="my-2">
                  <a className="api-item" href="/#">
                    Returns &amp; Refunds
                  </a>
                </li>
                <li className="my-2">
                  <a className="api-item" href="/#">
                    Technical issues
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={12} sm={12} className="mt-5">
              <img src="images/info.svg" height="15px" width="15px" alt="" />{" "}
              <span>Can't find an answer?</span>
              <Row>
                <Col lg={6} sm={12} md={6}>
                  <div className="email-icon-container">
                    {" "}
                    <Row className="ml-5 pl-2 pt-5">
                      <Col lg={12} sm={12}>
                        <span className="ml-4 mb-2">
                          <img
                            width="23px"
                            height="18px"
                            src="images/mail.svg"
                            alt=""
                          />
                        </span>{" "}
                      </Col>
                      <Col lg={12} sm={12}>
                        <span className="mail-img-text">Email us</span>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={6} sm={12} md={6}>
                  <div className="email-icon-container">
                    <Row className="ml-5 pl-2 pt-5">
                      <Col lg={12} sm={12}>
                        <span className="ml-4 mb-2">
                          <img
                            width="23px"
                            height="18px"
                            src="images/phone.svg"
                            alt=""
                          />
                        </span>{" "}
                      </Col>
                      <Col lg={12} sm={12}>
                        <span className="mail-img-text">Call us</span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col lg={7} md={7} sm={12}>
            <Row>
              <Col lg={12} sm={12} md={12}>
                <span className="faq-breadcrumb">FAQs &gt; API</span>
                <span className="ml-1 faq-title">API</span>
                <hr />
              </Col>
            </Row>
            <Row className="mb-1">
              <Col lg={12} sm={12}>
                <span className="list-number" style={{ marginRight: 10 }}>
                  1
                </span>
                <span className="list-title">Why is my API not working</span>
              </Col>
              <Col lg={12} sm={12}>
                <span className="list-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud{" "}
                </span>
              </Col>
            </Row>
            <Row className="py-1">
              <Col lg={12} sm={12}>
                <span className="list-number" style={{ marginRight: 10 }}>
                  2
                </span>
                <span className="list-title">When do I apply code?</span>
              </Col>
              <Col lg={12} sm={12}>
                <span className="list-text">
                  Lorem ipsum dolor sit amet,{" "}
                  <span className="list-title">
                    consectetur adipiscing elit
                  </span>
                  , sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  ullamco laboris nisi ut aliquip ex ea commodo
                  consequat.laborum.{" "}
                </span>
              </Col>
              <Col lg={12} sm={12}>
                <span className="mt-2 pt-1 list-title">Read more</span>
              </Col>
            </Row>
            <Row className="pt-1 pb-4">
              <Col lg={12} sm={12}>
                <span className="list-number" style={{ marginRight: 10 }}>
                  3
                </span>
                <span className="list-title">
                  What are the Transaction fees?
                </span>
              </Col>
              <Col lg={12} sm={12}>
                <span className="list-text">
                  Facilisis leo vel fringilla est ullamcorper. Venenatis lectus
                  magna fringilla urna porttitor rhoncus dolor purus. Nulla at
                  volutpat diam ut venenatis tellus in metus vulputate. Faucibus
                  pulvinar elementum integer enim neque volutpat ac. Consequat
                  interdum varius sit amet mattis vulputate enim. Pharetra massa
                  massa ultricies mi quis hendrerit dolor magna eget.{" "}
                </span>
              </Col>
            </Row>
            <Row className="pt-5 mt-5">
              <Col lg={6} sm={12}>
                <span className="list-title">Was this article helpful?</span>
              </Col>
              <Col lg={6} sm={12} className="float-end">
                <div className="float-end">
                  {" "}
                  <span className="list-title">7</span>
                  <img
                    className="mr-3"
                    width="20px"
                    height="20px"
                    src="images/thumbs-down.svg"
                    alt=""
                  />
                  <span className="list-title">185</span>
                  <img
                    width="20px"
                    height="20px"
                    src="images/thumbs-up.svg"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
