import React from "react";
import { connect } from "react-redux";
// import { toastr } from "react-redux-toastr";
import {
  editCompany,
} from "../../../actions/companyActions";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import TblComp from "../../../datatable";
import { Link } from "react-router-dom";

const Table = ({ dispatch, company_list, location }) => {
  const [dropDownState, setDropDownState] = React.useState(false);

  const [rowIdx, setRowIdx] = React.useState(0);
  const toggleDdState = (itemId) => {setRowIdx(itemId); setDropDownState(!dropDownState);};

  return (
    <div className="content-box-header panel-heading table-container">
      <Link
        className="btn payment-btn my-3 text-white"
        to="/company/new"
        replace={location.pathname.includes("company/new")}
      >
        New Company{" "}
      </Link>
      <TblComp
        columns={[
          {
            Header: "Company Name",
            accessor: "company_name",
            filter: "fuzzyText",
          },
          { Header: "Reg. No.", accessor: "reg_no", filter: "fuzzyText" },
          {
            Header: "Company Type",
            accessor: "company_type",
            filter: "fuzzyText",
          },
          {
            Header: "Description",
            accessor: "description",
            filter: "fuzzyText",
          },
          { Header: "Address", accessor: "address", filter: "fuzzyText" },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 100,
            accessor: (full) => (
              <Dropdown
                isOpen={rowIdx === full.id && dropDownState}
                toggle={()=>toggleDdState(full.id)}
              >
                <DropdownToggle size="sm" outline>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={(e) => dispatch(editCompany(full))}>
                    Edit
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      title="View Details"
                      to={"/company/" + full.id}
                      key={full.id}
                    >
                      View
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    {/* <Delete style={{ color: "red" }} fontSize="small" /> */}
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ),
          },
        ]}
        data={company_list}
      />
    </div>
  );
};

// connect the store for the company dispatches and state
const mapStateToProps = (state) => {
  return {
    company_list: state.companyState.company_list,
  };
};

const CompanyTableComp = connect(mapStateToProps)(Table);
export default CompanyTableComp;
