import jwt_decode from "jwt-decode";
import { decData, encData } from "../constants";

const authInitState = {
  log_out: false,
  isAuthenticated: false,
  user_obj: decData(sessionStorage.getItem("user")),
  loader: false,
  cam_send_otp:false,
  is_verifying:false,
  countdown:0,
  is_loading: false,
  link_valid: false,
  email_sent: false,
  login_status: {},
  login_page:"default",
  error_message: null,
  exp_time: 0,
};


export const AuthReducer = (prevState = authInitState, action) => {
  let new_state = prevState;
  switch (action.type) {
    case "DO_AUTH_ERROR":
      new_state = {
        ...prevState,
        isAuthenticated: false,
        error_message: action.payload,
      };
      break;
    case "SET_LOGIN_STATUS":
      new_state = {
        ...prevState,
        login_status: action.payload,
        error_message: null,
      };
      break;
    case "SET_LOGIN_PAGE_NAME":
      new_state = {
        ...prevState,
        login_page: action.payload
      };
      break;
      case "SET_CAN_SEND_OTP":
        new_state = {
          ...prevState,
          can_send_otp: action.payload
        };
        break;
      case "SET_IS_VERIFYING":
        new_state = {
          ...prevState,
          is_verifying: action.payload
        };
        break;
    case "SET_COUNTDOWN_TIMER":
      new_state = {
        ...prevState,
        countdown: action.payload
      };
      break;
    case "REDUCE_COUNTDOWN_TIME":
      new_state = {
        ...prevState,
        countdown: prevState.countdown - action.payload
      };
      break;
    case "DO_AUTH_SUCCESS":
      sessionStorage.setItem("token", encData(action.payload.access_token));
      sessionStorage.setItem("refresh_token",encData(action.payload.refresh_token));
      new_state = { ...prevState, isAuthenticated: true, error_message: null };
      break;
    case "ADD_USER_SESSION":
      sessionStorage.setItem("user", encData(action.payload));
      new_state = { ...prevState, user_obj: action.payload };
      break;
    case "UPDATE_USER_SESSION":
      const userSession = sessionStorage.getItem("user");
      const token = sessionStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwt_decode(decData(token));
          const currentTimeStamp = Math.floor(new Date().getTime() / 1000) - 3 * 60;
          new_state = {
            ...prevState,
            user_obj:userSession?decData(userSession):prevState.user_obj,
            isAuthenticated:decodedToken.exp > currentTimeStamp,
            exp_time: decodedToken.exp,
          };
        } catch (error) {
          console.error(error);
        }
      }
      break;
    case "UPDATE_BALANCE":
      //update the company's account balance
      const stored_user = decData(sessionStorage.getItem("user"));
      stored_user.active_company.acc_balance = action.acc_balance;
      sessionStorage.setItem("user", encData(stored_user));
      new_state = { ...prevState, user_obj: stored_user };
      break;
    case "UPDATE_ACTIVE_COMPANY":
      const user_obj = decData(sessionStorage.getItem("user"));
      user_obj.active_company = action.active_company;
      sessionStorage.setItem("user", encData(user_obj));
      new_state = { ...prevState, user_obj };
      break;
    case "SIGNOUT_USER":
      sessionStorage.clear();
      new_state = {
        ...prevState,
        isAuthenticated: false,
        user_obj: null,
        login_page: "default",
        login_status: {},
        exp_time: 0,
      };
      break;
    case "EMAIL_VERIFIED":
      let user = decData(sessionStorage.getItem("user"));
      user.email_verified_at = new Date().toUTCString();
      sessionStorage.setItem("user", encData(user));
      new_state = {
        ...prevState,
        user_obj: user,
        link_valid: false,
        loader: false,
      };
      break;
    case "LINK_EXPIRED":
      new_state = { ...prevState, link_valid: false };
      break;
    case "CODE_VERIFY_FAILED":
      new_state = { ...prevState, error_message: action.payload };
      break;
    case "EMAIL_SENT":
      new_state = { ...prevState, email_sent: true };
      break;
    case "IS_LOADING":
      new_state = { ...prevState, loader: action.loader };
      break;
    case "IS_PROCESSING":
      new_state = {
        ...prevState,
        is_loading: action.payload,
        error_message: null,
      };
      break;

    default:
    //throw new Error("Invalid auth Action Type");
  }
  return new_state;
};
