import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Loader from "./loader";

const DeleteModal = (props) => {
  const { modal, toggle, name, handleDelete, is_processing } = props;
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete a {name}</ModalHeader>
        {is_processing ? (
          <div className="loader-overlay">
            <Loader isModal />
          </div>
        ) : (
          <></>
        )}
        <ModalBody>This action is Irreversible !</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleDelete()}>
            {is_processing ? "... deleting" : "Yes"}
          </Button>
          <Button color="secondary" onClick={() => toggle()}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteModal;
