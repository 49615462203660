import React from "react";
import { ErrorMessage, useField } from "formik";
import { Input } from "reactstrap";

export const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={meta.touched && meta.error ? "is-invalid mb-4" : "mb-2"}>
      <Input
        className={`form-control input-field shadow-none mt-2 ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        autoComplete="off"
      />
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};
