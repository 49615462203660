const authInitState = {
  is_loading: false,
  step: 1,
  liquidation_response: {},
};

export const LiquidationReducer = (prevState = authInitState, action) => {
  let new_state = prevState;
  switch (action.type) {
    case "LIQUIDATION_PROCESSING":
      new_state = {
        ...prevState,
        is_loading: action.payload,
      };
      break;
    case "LIQUIDATION_SUCCESS":
      new_state = {
        ...prevState,
        liquidation_response: action.payload,
      };
      break;
    case "UPDATE_STEP":
      new_state = {
        ...prevState,
        step: action.payload,
      };
      break;

    default:
  }
  return new_state;
};
