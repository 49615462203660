import React from "react";
import { Link } from "react-router-dom";
import { Icons } from "./icons";
import sideBarStyles from "../../assets/css/sideBar.module.css";
import { Col, NavbarToggler, Navbar, Collapse } from "reactstrap";

export const NavItem = (props) => {
  const { location_obj, icon, icon_active, item_name, path, toggleNavbar } =
    props;
  const cur_location =
    location_obj.pathname != null && location_obj.pathname.includes(path);
  if (props.mobile_view) {
    return (
      <li className={`nav-item${cur_location ? " active" : ""}`}>
        <Link to={props.path} className="nav-link login-nav-btn">
          {item_name}
        </Link>
      </li>
    );
  }

  return (
    <li className={cur_location ? "current" : ""} onClick={toggleNavbar}>
      <Link
        to={props.path}
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
      >
        <img
          src={cur_location ? icon_active : icon}
          height={15}
          width={15}
          className="mr-1"
          alt=""
        />{" "}
        {item_name}
        {cur_location && (
          <img
            src="images/caret.svg"
            className="float-end"
            height="15px"
            width="15px"
            alt=""
          />
        )}
      </Link>
    </li>
  );
};

export const SideBar = (props) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const show_item =
    props.session_data.active_company != null &&
    props.session_data.active_company.status === "approved";
  const nav_arr = [
    {
      path: "/dashboard",
      show_item,
      item_name: "Dashboard",
      icon_active: Icons.dashboard_active_icon,
      icon: Icons.dashboard_icon,
    },
    {
      path: "/transactions",
      show_item,
      item_name: "Transactions",
      icon: Icons.products_icon,
      icon_active: Icons.products_active_icon,
    },
    // {path:"/transactions",show_item,item_name:'Transactions',icon:Icons.transactions_icon,icon_active:Icon.transactions_active_icon},
    {
      path: "/PaymentRequests",
      item_name: "Payment Links",
      show_item,
      icon: Icons.transaction_icon,
      icon_active: Icons.transaction_active_icon,
    },
    {
      path: "/reports",
      item_name: "Reports",
      show_item,
      icon: Icons.transaction_icon,
      icon_active: Icons.transaction_active_icon,
    },
    //{path:"/sandbox",item_name:'Sandbox',show_item,icon:Icons.sandbox_icon,icon_active:Icons.sandbox_active_icon},
    {
      path: "/faq",
      item_name: "FAQ",
      show_item: true,
      icon_active: Icons.faq_active_icon,
      icon: Icons.faq_icon,
    },
    {
      path: "/support_tickets",
      show_item: true,
      item_name: "Tickets",
      icon_active: Icons.support_active,
      icon: Icons.support,
    },
  ];
  return (
    <>
      <Col
        md={3}
        lg={2}
        sm={12}
        className={`${sideBarStyles.mobileView}  pr-0`}
      >
        <div className={`${sideBarStyles.fixedSidebar}`}>
          <div
            className={`sidebar ${sideBarStyles.contentBox}  ${sideBarStyles.sidebar}`}
          >
            <ul className="nav sidebar_nav">
              {/* Main menu */}
              {typeof props !== "undefined" &&
                nav_arr.map((nav_item, key) => {
                  if (nav_item.show_item) {
                    return (
                      <NavItem
                        key={key}
                        {...nav_item}
                        location_obj={props.location_obj}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </ul>
          </div>
          <div className="sidebar1 sidebar_content">
            <ul className="nav">
              <li>
                <a href="/">Contact Details</a>
              </li>
              <li>
                <a href="tel:+256200902091">+256 (0) 200 902 091</a>{" "}
                <a href="mailto:support@sentepay.com" title="Send us email">
                  support@sentepay.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Col>

      <Navbar light className="navbar-toggler-left" fixed="top">
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
      </Navbar>
      <Collapse isOpen={!collapsed} navbar>
        <div
          className={` sidebar ${sideBarStyles.contentBox} ${sideBarStyles.sidebar}`}
        >
          <ul className={`nav ${sideBarStyles.sideNav}`}>
            {typeof props !== "undefined" &&
              nav_arr.map((nav_item, key) => {
                if (nav_item.show_item) {
                  return (
                    <NavItem
                      key={key}
                      mobile_view={false}
                      {...nav_item}
                      location_obj={props.location_obj}
                      toggleNavbar={toggleNavbar}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </ul>
        </div>
        <div className={`${sideBarStyles.sidebar2} sidebar1`}>
          <ul className="nav">
            <li>
              <a href="/">Contact Details</a>
            </li>
            <li>
              <a href="tel:+256200902091">+256 (0) 200 902 091</a>{" "}
              <a href="mailto:support@sentepay.com" title="Send us email">
                support@sentepay.com
              </a>
            </li>
          </ul>
        </div>
      </Collapse>
    </>
  );
};
