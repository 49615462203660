// this is the form state
const userFormData = {
  firstname: "",
  lastname: "",
  password: "",
  phone_number: "",
  password_confirmation: "",
  country_id: "",
  email: "",
  gender: "",
  address: "",
  tcs_accepted: "0",
};
//this is the component
const userInitState = {
  redirectToMailSent: false,
  currentFormObj: userFormData,
  modalIsOpen: false,
  is_loading: false,
  form_errors: "",
};

export const userReducer = (prevState = userInitState, action) => {
  let new_state = {};
  switch (action.type) {
    case "USER_INPUT_CHANGE":
      const new_formState = {
        ...prevState.currentFormObj,
        ...action.changedField,
      };
      new_state = { ...prevState, currentFormObj: new_formState };
      break;
    case "CLEAR_USER_FORM":
      new_state = { ...prevState, currentFormObj: userFormData };
      break;
    case "ADD_USER_ERROR":
      new_state = { ...prevState, redirectToMailSent: false };
      break;
    case "ADD_USER_SUCCESS":
      new_state = {
        ...prevState,
        currentFormObj: userFormData,
        redirectToMailSent: action.success,
      };
      break;

    case "REG_SUCCESS_MODAL_SHOW":
      new_state = { ...prevState, modalIsOpen: action.status };
      break;
    case "REGISTERATION_IS_PROCESSING":
      new_state = { ...prevState, is_loading: action.payload };
      break;
    default:
      new_state = prevState;
  }
  return new_state;
};
