import React, { useEffect } from "react";
import { connect } from "react-redux";
import CompanyDocs from "./documents/company_docs";
import CompanyDetails from "./company_details";
import {
  editCompany,
  HandleCompanyFetch,
} from "../../../actions/companyActions";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

const GoLive = ({company_list,session_data,dispatch}) => {
  const company_id = company_list.length ? company_list[0].id : 0;
  useEffect(() => {
    if (session_data.active_company == null) {
      company_id !== 0 && dispatch(editCompany(company_list[0]));
    }
    if (
      session_data.active_company !== null &&
      company_list.length === 0
    ) {
      dispatch(HandleCompanyFetch(session_data.id));
    }
  }, [dispatch,company_id,company_list,session_data]);

  return (
    <Card>
      <CardHeader>
        <span className="doc-header col-12">
          Business Registration
          <span className="required-text">
            {" "}
            - Let's get your business registered!
          </span>
        </span>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm={6} lg={6} md={6}>
            <CompanyDetails />
          </Col>
          <Col sm={6} lg={6} md={6}>
            <CompanyDocs />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

// connect the store for the company dispatches and state
const mapStateToProps = (state) => {
  return {
    session_data: state.authState.user_obj,
    company_list: state.companyState.company_list,
  };
};
const GoLiveRedux = connect(mapStateToProps)(GoLive);
export default GoLiveRedux;
