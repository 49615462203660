import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

const AuthFooter = () => {
  return (
    <div id="auth-footer" className="privacy-text">
      <Row>
        <Col>
          <div className="ps-3">
            <Link to="/privacy_policy">Privacy Policy</Link> |
            <Link to="/terms_of_use">Terms &amp; Conditions</Link>
          </div>
        </Col>
        <Col>
          <span className="float-end pe-3">
            {" "}
            All Rights Reserved. GMT Consults {new Date().getFullYear()}
          </span>
        </Col>
      </Row>
    </div>
  );
};
export default AuthFooter;
