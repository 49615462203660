import React, { useRef } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
} from "reactstrap";

const DocumentPreview = ({
  DocTypes,
  company_document_list,
  handleLinkClick,
  isActive,
  currentDoc,
  HandleCompanyDocumentSubmit,
  company,
  prevModal,
  prevToggle,
}) => {
  const dispatch = useDispatch();
  const fileInput = useRef(null);

  const handleFileUpload = (document_type_id) => (e) => {
    e.preventDefault();
    const { files } = e.target;
    dispatch(
      HandleCompanyDocumentSubmit({
        id: currentDoc.id,
        document_type_id,
        company,
        doc_file: files[0],
      })
    );
  };

  const handleUploadBtnClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };
  return (
    <>
      {/* preview document modal */}
      <Modal isOpen={prevModal} toggle={prevToggle} size="lg">
        <ModalHeader toggle={prevToggle}>
          <span className="col-11">Preview Document</span>
        </ModalHeader>
        <ModalBody>
          <div style={{ height: "500px" }}>
            <ul class="nav nav-tabs mb-2" role="tablist">
              {company_document_list.map((document, id) => (
                <li class="nav-item pr-2" key={`fpkey${id}`}>
                  <Button
                    onClick={() => [handleLinkClick(document)]}
                    key={document.id}
                    className={
                      parseInt(isActive) === parseInt(id)
                        ? "bg-warning text-white nav-link nav-buttons"
                        : "nav-link nav-buttons"
                    }
                    data-toggle="tab"
                    href="#tabs-3"
                    role="tab"
                  >
                    {DocTypes(document)}
                  </Button>
                </li>
              ))}
            </ul>
            <Row>
              <Col lg={6} sm={12} md={12}>
                <Input
                  ref={fileInput}
                  type="file"
                  name="doc_file"
                  hidden
                  onChange={handleFileUpload(currentDoc.document_type_id)}
                />
                <div
                  className="btn-group"
                  role="group"
                  aria-labelledby={`label`}
                >
                  {currentDoc.approved === 0 ||
                  currentDoc.approval_text === "changes_requested" ? (
                    <Button
                      type="button"
                      size="sm"
                      className="btn-info ml-3 mb-2"
                      title={"Replace file"}
                      onClick={handleUploadBtnClick}
                    >
                      <img
                        src="/images/upload.svg"
                        height="15px"
                        width="15px"
                        alt="upload"
                        className="ml-1"
                      />
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col lg={6} sm={12} md={12}>
                {currentDoc.approved === 1
                  ? "Comment: " + currentDoc.comment
                  : ""}
              </Col>
            </Row>

            <iframe
              title="
                "
              src={currentDoc.dope_name}
              frameBorder="0"
              scrolling="auto"
              height="70%"
              width="100%"
            ></iframe>
          </div>
        </ModalBody>
      </Modal>

      {/* end of preview modal */}
    </>
  );
};

export const DocumentPreviewReduxComp = withRouter(DocumentPreview);
