import React, { useState } from "react";
import moment from "moment";
import {useSelector,shallowEqual} from "react-redux";
import TblComp from "../../../datatable";
import { currFormat } from "../../../constants/GenHelpers";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";

export const PaymentTable = (props) => {
  const {transactionList:data,total,pageCount} = useSelector((state) => state.paymentState,shallowEqual);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [single, setSingle] = useState();
  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Transaction Details</ModalHeader>
        <ModalBody>
          { typeof single !== "undefined" ? <><Row>
            <Col lg={6} sm={12} md={6} className="font-weight-bold">
              Reference Number
            </Col>
            <Col lg={6} sm={12} md={6}> {single.request_ref} </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold ">
              Amount
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              {currFormat((single.debit ? single.debit : single.credit) * 1) }
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold">
              Status
            </Col>
            <Col lg={6} md={6} sm={12} className="my-2 font-weight-bold">
              <b> { single.status_text } </b>
            </Col>
          </Row>
          {/* typeof single.currency !== "undefined" && single.currency && <Row>
            <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold">
              Currency
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              { single.currency.currency_code }
            </Col>
          </Row> */}
          { single.msisdn && <Row>
            <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold">
              Phone
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              {single.msisdn}
            </Col>
          </Row>}
          <Row>
            <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold">
              Narrative
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              { single.transaction_note }
            </Col>
          </Row>
          {typeof single.sp_country !== "undefined" && single.sp_country && <Row>
            <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold ">
              Service Provider (Code)
            </Col>
            <Col lg={6} sm={12} md={6} className="my-2">
              { single.sp_country.service_provider && single.sp_country.service_provider.service_provider_name }(
              {typeof single !== "undefined"
                ? typeof single.sp_country !== "undefined" &&
                  single.sp_country &&
                  single.sp_country.spc_code
                : "Loading..."})
            </Col>
          </Row>}
          <Row>
            <Col lg={6} sm={12} md={6} className="my-2 font-weight-bold ">
              Transaction Date and Time
            </Col>
            <Col lg={6} sm={12} md={6} className="col-6 my-2">
              { typeof single && moment(single.transaction_date).format("MMMM Do YYYY, h:mm:ss a")
                }
            </Col>
          </Row>
          </>: <p>"Loading txn details..."</p> }
        </ModalBody>
      </Modal>
      {/*{
            id: "currency", // Required because our accessor is not a string
            Header: "Currency",
            accessor: "currency",
            // maxWidth: 100,
            Cell: (props) => (props.value ? props.value.currency_code : ""),
          }*/}
      <TblComp
        columns={[
          {
            id: "transaction_date",
            Header: "Date",
            accessor: "transaction_date",
            filter: "fuzzyText",
            Cell: ({ value }) =>
              value ? moment(value).format("D-M-YYYY h:mm:ss a") : "",
          },
          {
            Header: "Ref No.",
            maxWidth: 200,
            accessor: "request_ref",
            filter: "fuzzyText",
          },
          {
            id: "debit",
            Header: "Debit",
            accessor: "debit",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            id: "credit",
            Header: "Credit",
            accessor: "credit",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            Header: "Charge",
            accessor: "charges",
            filter: "fuzzyText",
            Cell: ({ value }) => (value ? currFormat(value * 1) : ""),
          },
          {
            id: "balance", // Required because our accessor is not a string
            Header: "Balance",
            accessor: "balance",
            Cell: ({ value }) => (value ? currFormat(value*1) : ""),
          },
          { Header: "Phone", accessor: "msisdn", filter: "fuzzyText" },
          {
            id: "sp_country", // Required because our accessor is not a string
            Header: "Provider",
            accessor: "sp_country",
            Cell: ({ value }) => (value ? value.spc_code : ""),
          },
          { Header: "Status", accessor: "status_text", filter: "fuzzyText" },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Action",
            maxWidth: 150,
            accessor: (full) => (
              <div className="d-flex">
                <Button
                  size="sm"
                  onClick={(e) => [
                    // e.preventDefault(),
                    toggle(),
                    setSingle(full),
                  ]}
                >
                  <img src="/images/view.svg" alt="view" className="ms-1" />
                </Button>
                {/* <ApproveActions/> */}
                {/* <Actions  editHandler={props.editHandler}  data_item={full} link_url ={`company_details/`} modal_id="company-modal" deleteHandler={props.deleteHandler}/> */}
              </div>
            ),
          },
        ]}
        data = {data}
        total = {total}
        pageCount = {pageCount}
        serverSide ={true}
        {...props}
      />
    </>
  );
};
