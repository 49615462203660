import React,{ useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  doAuthAction,
  checkAuthentication
} from "../../actions/authAction";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import * as Yup from "yup";
import AuthFooter from "./authFooter";
import { Row, Col, Button, Container } from "reactstrap";
import OTPComponent from "./OTP";

import AuthHeader from "./authHeader";
import WelcomeText from "./welcomeText";

//this component should only be rendered when the user isn't authenticated
function Login(props) {
  const {
    location: from,
    isAuthenticated,
    login_page,
    error_message,
    is_loading
  } = props;
  // const dispatch = useDispatch();

  const validate = Yup.object({
    username: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

    /**
     * It is possible for this to be a page reload, so we want to ensure the user Data is already in the local storage and we aren't missing it.
     */
    useEffect(() => {
      props.dispatch(checkAuthentication());
  });

  if (isAuthenticated === true) {
    return <Redirect to={typeof from !== "undefined" ? from : "/"} />;
  }
  return <>
  {is_loading && <>{/* <p>content goes here</p> */}</> }
      <Container fluid style={{ height: "100vh" }}>
        <AuthHeader />
        <Row className="mt-1">
          <Col lg={7} md={6} sm={12} className="pt-5 px-5 text-center">
            <WelcomeText
              welcome="Welcome Back to"
              description="Always keep your account information and security details safe"
              shortDescription="to avoid any inconveniences."
            />
          </Col>
          <Col lg={5} md={6} sm={12}>
            <div
              className="login-wrapper w-75 mx-auto"
              style={{ display: error_message === null ? "none" : "" }}
            >
              <i className="fa fa-times"></i>
              <p className="text-danger">{error_message}</p>
            </div>
            <div className="login-wrapper w-75 mx-auto">
              <div className="box">
                <div>
                  <Formik
                    initialValues={{
                      username: "",
                      password: "",
                    }}
                    validationSchema={validate}
                    onSubmit={(values) => {
                      props.dispatch(doAuthAction(values));
                    }}
                  >
                    {(formik) => (
                      <div>
                        <Form className="border border-light p-3">
                          {login_page === "OTP" && <OTPComponent/> }
                          { login_page === "default" && 
                            <>
                              <p className="h4 mb-4 text-center">Sign in</p>
                              <TextField
                                name="username"
                                type="email"
                                placeholder="E-mail"
                              />
                              <TextField
                                name="password"
                                type="password"
                                placeholder="Password"
                              />
                              {is_loading === true ? (
                                <>
                                  <Button
                                    className="sign-in-btn shadow-none text-white"
                                    block
                                    disabled={is_loading}
                                  >
                                    <div
                                      className="spinner-grow spinner-grow-sm"
                                      role="status"
                                    ></div>
                                    Signing in...
                                  </Button>{" "}
                                </>
                              ) : (
                                <>
                                  <Button
                                    className="sign-in-btn my-1 shadow-none mb-4 text-white"
                                    block
                                    type="submit"
                                  >
                                    Sign in
                                  </Button>
                                </>
                              )}
                              <div className="d-flex justify-content-center">
                                <div className="already float-right">
                                  <Link
                                    to="forgotPassword"
                                    className="sign-up-text"
                                  >
                                    Forgot password?
                                  </Link>
                                </div>
                              </div>
                              <div className="text-center">
                                <div className="already">
                                  <p className="sign-up-text">
                                    Don't have an account yet?
                                  </p>
                                  <Link to="/sign_up" className="sign-up-text">
                                    Sign Up
                                  </Link>
                                </div>
                              </div>
                            </>
                          }
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <AuthFooter />
      </Container>
    </>
}
const mapAuthStateToProps = (state) => {
  return {
    isAuthenticated: state.authState.isAuthenticated,
    is_loading: state.authState.is_loading,
    res_status: state.authState.res_status,
    login_page:state.authState.login_page,
    error_message: state.authState.error_message,
  };
};
export const LoginUser = connect(mapAuthStateToProps)(Login);

export default LoginUser;
