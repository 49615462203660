import React, { useEffect } from "react";
import MaskedInput from "react-maskedinput";
import refresh from "../../../../assets/img/refresh.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField } from "../../../auth/TextField";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  ModalHeader,
  Modal,
  ModalBody,
  FormGroup,
  ModalFooter,
  Container,
  Button,
} from "reactstrap";
import { HandlePaymentInput } from "../../../../actions/payment_actions";
import { HandleCollectionSubmit, InitCollection } from "../../../../actions/collection_actions";
import { HandleCompanyFetch } from "../../../../actions/companyActions";

const TransactionResult = ({
  headline = "rejected",
  message = "has been rejected",
}) => {
  return (
    <Row>
      <span className="upgrade-2 text-center ms-5">
        Transaction In {headline}:{" "}
      </span>
      <Col lg={12} sm={12} md={12} className="d-flex justify-content-center">
        <span className="loader-text-2">The transaction {message}.</span>
      </Col>
    </Row>
  );
};

const Informational = ({
  company_id,
  step,
  modal,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <Container className="mt-1">
        <Row>
          {step === 5 && (
            <TransactionResult
              headline="Failure: network"
              message="could not be completed due to a network / server failure."
            />
          )}
          {step === 4 && <TransactionResult />}
          {step === 3 && (
            <TransactionResult
              headline="completed"
              message="was successfully completed"
            />
          )}
        </Row>
        {step === 2 && (
          <>
            <div className="d-flex justify-content-center">
              <img src={refresh} width="100px" height="100px" alt="" />
            </div>
            <hr />
            <div className="d-flex justify-content-center">
              <span className="upgrade-2 text-center">Status: </span>{" "}
              <span className="upgrade-1 ms-1">Processing Transaction...</span>
            </div>
            <Row>
              <Col
                lg={12}
                sm={12}
                md={12}
                className="d-flex justify-content-center"
              >
                <span className="loader-text-2">
                  A prompt will appear on your phone screen.
                </span>
              </Col>
              <Col
                lg={12}
                sm={12}
                md={12}
                className="d-flex justify-content-center"
              >
                <span className="loader-text">
                  Please authorize the payment.
                </span>
              </Col>
            </Row>
          </>
        )}
      </Container>

      <ModalFooter>
        {(step === 4 || step === 5) && (
          <Button
            onClick={() =>
              (step === 4 || step === 5) && dispatch(InitCollection(1))
            }
            className="pay-btn"
          >
            {" "}
            <span className="text-white">Try again</span>
          </Button>
        )}
        {step === 2 &&
          (modal ? (
            <Button
              onClick={(e) => {e.preventDefault();dispatch(HandleCompanyFetch(company_id, true));}}
              className="pay-btn"
            >
              {" "}
              <span data-dismiss="modal" className="text-white">
                Close
              </span>
            </Button>
          ) : (
            ""
          ))}
      </ModalFooter>
    </>
  );
};

export const AddCollection = (props) => {
  const dispatch = useDispatch();
  const {modalClose,step} = useSelector((state) => state.collectionState,shallowEqual);
  const user_obj = useSelector((state) => state.authState.user_obj,shallowEqual);

  const {
    location: { subPlanId, subPlanAmount },
    toggle,
    modal,
  } = props;

  const validate = Yup.object({
    masked_contact: Yup.string().required("Contact is required"),
    currency_id: Yup.string().required("Currency Id is required"),
    amount: Yup.string().required("Amount is required"),
    transaction_note: Yup.string().required("Transaction note is required"),
  });

  useEffect(() => {
    //in case they were being redirected from the attempt to subscribe
    subPlanAmount &&
      user_obj && user_obj.active_company && user_obj.active_company.acc_balance &&
      dispatch(HandlePaymentInput({
        name: "amount",
        value: subPlanAmount - user_obj.active_company.acc_balance,
      }));
  }, [user_obj,subPlanAmount,dispatch]);
  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span className="modal-header-text">Make Collection</span>
        </ModalHeader>
        <ModalBody>
          {step > 1 && (
            <Informational
              step={step}
              company_id={user_obj.active_company.id}
              modal={modalClose}
            />
          )}
          {step === 1 && (
            <Formik
              initialValues={{
                masked_contact: "",
                amount: "",
                transaction_note: "",
                currency_id: "1",
              }}
              validationSchema={validate}
              onSubmit={(values) => {
                dispatch(HandleCollectionSubmit(values, user_obj.active_company.id, subPlanId));
              }}
            >
              {(formik) => (
                <Form>
                  <div className="container-fluid">
                    <FormGroup className="mt-3">
                      <Row>
                        <TextField
                          name="currency_id"
                          type="hidden"
                          placeholder="Amount"
                        />
                        <Col lg={12} md={12} sm="12" className="my-2">
                          <Field
                            name="masked_contact"
                            render={({ field }) => (
                              <MaskedInput
                                {...field}
                                mask="+256-111-111-111"
                                showMask
                                className="form-control"
                              />
                            )}
                          />
                          <ErrorMessage
                            component="div"
                            name="masked_contact"
                            className="error"
                          />
                        </Col>
                        <Col lg={12} md={12} sm={12} className="my-2">
                          <TextField
                            name="amount"
                            type="text"
                            placeholder="Amount"
                          />
                        </Col>
                        <Col lg={12} md={12} sm="12" className="my-2">
                          <Field
                            as="textarea"
                            name="transaction_note"
                            placeholder="Transaction note"
                            className={`custom-select form-control ${
                              formik.errors.transaction_note
                                ? `is-invalid mb-2`
                                : ""
                            }`}
                          ></Field>
                          <ErrorMessage
                            component="div"
                            name="transaction_note"
                            className="error"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>

                  <ModalFooter className="modal-footer">
                    <Button type="submit" className="pay-btn text-white" block>
                      <span className="pay-btn-text ">Collect</span>
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
