import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  clearCompanyDocumentForm,
  editCompanyDocument,
  HandleCompanyDocumentDelete,
  HandleCompanyDocumentFetch,
  HandleCompanyDocumentInput,
  HandleCompanyDocumentSubmit,
} from "../../../../actions/company_document_actions";
import { HandleDocumentTypeFetch } from "../../../../actions/types/document_type_actions";
import { Actions, Select } from "../../../../constants/constants";
import TblComp from "../../../../datatable";
import { DocumentPreviewReduxComp } from "./document_preview";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

export const CompanyDocument = (props) => {
  const [isActive, setActiveTab] = useState();
  const [currentDoc, setCurrentDoc] = useState({});
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const [prevModal, setPrevModal] = React.useState(false);
  const prevToggle = () => setPrevModal(!prevModal);
  const {
    current_company_document_obj: formState,
    company_document_list,
    dispatch,
    document_type_list,
    match,
  } = props;
  useEffect(() => {
    (typeof company_document_list !== "undefined" ||
      company_document_list.length === 0) &&
      dispatch(HandleCompanyDocumentFetch(match.params.id));
    (typeof document_type_list !== "undefined" ||
      document_type_list.length === 0) &&
      dispatch(HandleDocumentTypeFetch());
  }, [company_document_list, dispatch, document_type_list,match]);

  const DocTypes = (full) => {
    if (typeof props.document_type_list !== "undefined") {
      const current_doc = props.document_type_list.find(
        (document) => document.id === full.document_type_id
      );
      return current_doc !== undefined ? current_doc.document_name : <></>;
    }
    return <></>;
  };

  const handleLinkClick = (e) => {
    setCurrentDoc(e);
    setActiveTab(e.document_type_id - 1);
  };

  return (
    <>
      <Row>
        <DocumentPreviewReduxComp
          DocTypes={DocTypes}
          company_document_list={company_document_list}
          handleLinkClick={handleLinkClick}
          isActive={isActive}
          currentDoc={currentDoc}
          prevToggle={prevToggle}
          prevModal={prevModal}
          HandleCompanyDocumentSubmit={HandleCompanyDocumentSubmit}
          company={props.company}
        />
        <Col lg={12} md={12} sm={12}>
          <div className="content-box-header panel-heading table-container">
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>
                <span className="col-11">Add Document</span>
              </ModalHeader>
              <ModalBody>
                <Form
                  autoComplete="off"
                  method="post"
                  onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(
                      HandleCompanyDocumentSubmit(formState, match.params.id)
                    );
                  }}
                >
                  <FormGroup>
                    <div className="container-fluid">
                      <Row className="mt-3">
                        <Col lg={12} sm={12} md={12} className="mt-2">
                          <label className="ml-3 lead">Select doc type</label>
                          <Select
                            name="document_type_id"
                            index_key="id"
                            label_key="document_name"
                            value={formState.document_type_id}
                            options={document_type_list}
                            handleChange={(e) =>
                              dispatch(HandleCompanyDocumentInput(e))
                            }
                          />
                        </Col>

                        <Col lg={12} sm={12} md={12} className="mt-2">
                          <Input
                            autoComplete="off"
                            id="input"
                            type="file"
                            name="doc_file"
                            placeholder=""
                            className="form-control"
                            onChange={(e) =>
                              dispatch(HandleCompanyDocumentInput(e))
                            }
                          />
                          {formState.id && (
                            <strong className="text-danger">
                              *Your currently saved document will be deleted on
                              uploading another one{" "}
                            </strong>
                          )}
                        </Col>
                      </Row>
                    </div>

                    <div className="mt-3">
                      <Button type="submit" className="pay-btn" block>
                        <span className="pay-btn-text ">Add</span>
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </ModalBody>
            </Modal>

            <Button
              className="payment-btn my-3 text-white"
              onClick={(e) => [dispatch(clearCompanyDocumentForm(e)), toggle()]}
            >
              Add Document
            </Button>
            <TblComp
              columns={[
                {
                  id: "document_type_id",
                  Header: "Document type",
                  maxWidth: 100,
                  accessor: (full) => DocTypes(full),
                },
                {
                  id: "status",
                  Header: "Status",
                  maxWidth: 100,
                  accessor: (full) => (
                    <Button
                      size="sm"
                      color={full.approved === 0 ? "danger" : "success"}
                    >
                      {full.approval_text}
                    </Button>
                  ),
                },
                {
                  id: "actions", // Required because our accessor is not a string
                  Header: "Actions",
                  maxWidth: 100,
                  accessor: (full) => (
                    <>
                      <a
                        href
                        onClick={(e) => [
                          e.preventDefault(),
                          handleLinkClick(full),
                          prevToggle(),
                          // setCurrentDoc(full),
                          // setActiveTab(full.document_type_id - 1),
                        ]}
                      >
                        <span className="badge badge-info  btn badge-btn">
                          <img
                            src="/images/view.svg"
                            height="15px"
                            width="15px"
                            alt="view"
                            className="ml-1"
                          />
                        </span>
                      </a>
                      <Actions
                        modal_id="company_document-modal"
                        data_item={full}
                        editHandler={(record) =>
                          dispatch(editCompanyDocument(record))
                        }
                        deleteHandler={(rec_id) =>
                          dispatch(HandleCompanyDocumentDelete(rec_id))
                        }
                      />
                    </>
                  ),
                },
              ]}
              data={company_document_list}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
// connect the store for the company dispatches and state
const mapCompanyDocumentStateToProps = (state) => {
  return {
    company: state.companyState.current_company_obj.id,
    current_company_document_obj:
      state.companyDocumentState.current_company_document_obj,
    document_type_list: state.documenttypereducer.document_type_list,
    company_document_list: state.companyDocumentState.company_document_list,
  };
};
export const CompanyDocReduxComp = withRouter(
  connect(mapCompanyDocumentStateToProps)(CompanyDocument)
);
