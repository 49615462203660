import React from "react";
import sentepayLogo from "../../assets/img/sentepay-logo.png";

const WelcomeText = ({ welcome, description, shortDescription }) => {
  return (
    <>
      <p className="welcome-text">{welcome}</p>
      <div className="sente-pay-text-bold">
        <img className="sentepay-logo" alt="" src={sentepayLogo} />
      </div>
      <p className="welcome-note mt-3">
        {description}
        <br />
        {shortDescription}
      </p>
    </>
  );
};
export default WelcomeText;
