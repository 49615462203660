const DocumentTypeFormState = {
   document_name: '', description: '',
}
const DocumentTypeInitState = {
   document_type_list: [],
   current_document_type_obj: DocumentTypeFormState,
}

export const DocumentTypeReducer = (prevState = DocumentTypeInitState, action) => {
   const doc_form_state = prevState.current_document_type_obj
   let new_formState
   switch (action.type) {
      case 'DOCUMENT_TYPE_ERROR':
         return { ...prevState };
      case 'FETCH_DOCUMENT_TYPE_SUCCESS':
         return { ...prevState, document_type_list: action.payload }
      case 'DOCUMENT_TYPE_INPUT_CHANGE':
         new_formState = { ...doc_form_state, ...action.changedField }
         return { ...prevState, current_document_type_obj: new_formState };
      case 'CLEAR_DOCUMENT_TYPE_FORM':
         return { ...prevState, current_document_type_obj: DocumentTypeFormState };
      case 'EDIT_DOCUMENT_TYPE':
         new_formState = { ...action.current_document_type_obj }
         return { ...prevState, current_document_type_obj: new_formState };
      case 'DELETE_DOCUMENT_TYPE_SUCCESS':
         let new_list = prevState.document_type_list.filter(current_rec => current_rec.id !== action.record_id)
         console.log(new_list)
         return { ...prevState, document_type_list: new_list };
      case 'ADD_DOCUMENT_TYPE_SUCCESS':
         let document_type_new = true
         let document_type_list = prevState.document_type_list.map(document_type => {
            if (document_type.id === action.new_record.id) {
               document_type_new = false
               return action.new_record
            } else {
               return document_type
            }
         }
         )
         document_type_new && document_type_list.push(action.new_record)
         const new_state = { ...prevState, document_type_list }
         if (document_type_new) {
            new_state.current_document_type_obj = DocumentTypeFormState
         }
         return new_state;
      default:
         return prevState;
   }
}