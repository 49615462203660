import { allconstants } from "../constants/constants";

export const Progress = (payload) => {
  return { type: "LIQUIDATION_PROCESSING", payload };
};
export const LiquidationSuccess = (payload) => {
  return { type: "LIQUIDATION_SUCCESS", payload };
};
export const UpdateStep = (payload) => {
  return { type: "UPDATE_STEP", payload };
};

export const HandleClose = () => (dispatch) => {
  dispatch(UpdateStep(1));
};
export const HandleLiquidationSubmit =
  (form_data, companyId, setErrors, setSubmitting) => (dispatch) => {
    dispatch(Progress(true));
    const url = allconstants.apiurl + "funds/liquidation/" + companyId;
    const req_type = allconstants.axios.post;
    req_type(url, { ...form_data }, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          dispatch(Progress(false));
          dispatch(LiquidationSuccess(response.data));
          dispatch(UpdateStep(2));
          setSubmitting(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          dispatch(Progress(false));
          setSubmitting(false);
          setErrors(error.response.data.errors);
          console.error(error.response.data);
          console.error(error.response.status, error.response.statusText);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

export const VerifyLiquidationOtp = (txtRef, otp) => (dispatch) => {
  dispatch(Progress(true));
  const url = allconstants.apiurl + `funds/liquidation/verify/${txtRef}/${otp}`;
  const req_type = allconstants.axios.get;
  req_type(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(Progress(false));
        dispatch(UpdateStep(3));
      }
    })
    .catch((error) => {
      if (error.response) {
        // toastr.warning("Warning", "The Otp value entered is wrong");
        dispatch(Progress(false));
        console.error(error.response.data);
        console.error(error.response.status, error.response.statusText);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
};
