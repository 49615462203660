import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, NavbarToggler, Navbar, Collapse } from "reactstrap";
import loginImg from "../../assets/img/login-svg.png";

const AuthHeader = () => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  return (
    <Row>
      <Col lg={9} sm={12} md={9} className="d-none d-md-flex">
        <img
          width="800px"
          height="200px"
          src={loginImg}
          alt="SentePay Background"
        />
      </Col>
      <Col lg={3} md={3} sm={12}>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto">
              <li className="nav-item active">
                <Link to="/sign_up" className="nav-link login-nav-btn">
                  Sign Up
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link login-nav-btn" href="/#" alt="">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link login-nav-btn" href="/#">
                  Help &amp; Contact
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle login-nav-btn"
                  href="/#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  ENG
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </Col>
      <Col lg={3} md={3} sm={12}>
        <Navbar light className="navbar-toggler-left">
          <NavbarToggler onClick={toggleNavbar} className="me-2" />
        </Navbar>
        <Collapse isOpen={!collapsed} navbar>
          <ul className="navbar-nav me-auto">
            <li className="nav-item active">
              <Link to="/sign_up" className="nav-link login-nav-btn">
                Sign Up <span className="sr-only"></span>
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link login-nav-btn" href="/#" alt="">
                FAQ
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link login-nav-btn" href="/#">
                Help &amp; Contact
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle login-nav-btn"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                ENG
              </a>
            </li>
          </ul>
        </Collapse>
      </Col>
    </Row>
  );
};
export default AuthHeader;
