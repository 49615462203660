import React, { useCallback, useRef, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  clearPaymentForm,
  HandleTransactionsFetch,
  setPaymentResponse,
  setPaymentState,
} from "../../../actions/payment_actions";
import { InitCollection } from "../../../actions/collection_actions";
import { Select } from "../../../constants/constants";
import { AddPayment } from "./payments/add_payment";
import { PaymentTable } from "./table";
import { AddCollection } from "./collections/add_collection";
import { AddLiquidation } from "./liquidations/add_liquidation";
import { Button } from "reactstrap";

export const Products = ({ location }) => {
  const dispatch = useDispatch();
  const user_obj = useSelector(
    (state) => state.authState.user_obj,
    shallowEqual
  );
  const isLoading = useSelector(
    (state) => state.paymentState.is_loading,
    shallowEqual
  );

  const [transaction_status, setStatus] = useState("");
  const fetchIdRef = useRef(0);
  const [modal, setModal] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const toggleModal = () => {
    if (selectedModal === "payment") {
      dispatch(setPaymentResponse(""));
      dispatch(setPaymentState("new_payment")); //whenever the payment form is closed, we'll reset the form
    }
    setModal(!modal);
    modal && setSelectedModal("");
  };

  const fetchData = useCallback(
    (pageOptions) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        dispatch(
          HandleTransactionsFetch(user_obj.active_company.id, {
            ...pageOptions,
            status: transaction_status,
          })
        );
      }
    },
    [dispatch, user_obj, transaction_status]
  );

  return (
    <div className="content-box-header panel-heading table-container">
      <AddPayment
        toggle={toggleModal}
        modal={selectedModal === "payment" && modal}
      />
      <AddCollection
        toggle={toggleModal}
        modal={selectedModal === "collection" && modal}
        location={location}
      />
      <AddLiquidation
        toggle={toggleModal}
        modal={selectedModal === "liquidation" && modal}
      />

      <Button
        className="payment-btn my-1 me-1 my-md-3 ms-md-1 me-md-3 text-white"
        onClick={(e) => {
          e.preventDefault();
          dispatch(clearPaymentForm(e));
          toggleModal();
          setSelectedModal("payment");
        }}
      >
        New Payment
      </Button>
      <Button
        className="payment-btn my-1 my-md-3 mx-md-3 text-white"
        onClick={(e) => {
          e.preventDefault();
          dispatch(clearPaymentForm(e));
          dispatch(InitCollection(1));
          toggleModal();
          setSelectedModal("collection");
        }}
      >
        New Collection
      </Button>
      <Button
        className="payment-btn my-3 mx-3 text-white"
        onClick={(e) => {
          toggleModal();
          setSelectedModal("liquidation");
        }}
      >
        Withdraw
      </Button>
      <Button
        className="payment-btn my-3 ml-2 text-white"
        onClick={() => {
          fetchData({ page: 1, perPage: 10 });
        }}
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Refresh"}
      </Button>
      <Button color="link" className="my-1 me-1">
        {typeof user_obj.active_company.id !== "undefined" && (
          <Select
            placeholder="All Records"
            name="transaction_status"
            index_key="id"
            label_key="status_name"
            className="form-control"
            value={transaction_status}
            options={[
              { id: 2, status_name: "Pending Approval" },
              { id: 3, status_name: "Successful" },
              { id: 4, status_name: "Failed" },
            ]}
            handleChange={(e) => {
              setStatus(e.target.value);
            }}
          />
        )}
      </Button>
      <PaymentTable fetchData={fetchData} />
    </div>
  );
};

export default Products;
