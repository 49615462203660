import Axios from "axios";
// import { toastr } from "react-redux-toastr";
const { base_url } = require("../constants/constants");

/*const clearUserForm  = (e) =>{
    e.preventDefault();
  return {type: "CLEAR_USER_FORM"}
}*/

const addUserSuccess = (payload, msg) => {
  // toastr.success("Success", msg);
  return { type: "ADD_USER_SUCCESS", success: true };
};

export const showModal = (status = true) => {
  return {
    type: "REG_SUCCESS_MODAL_SHOW",
    status: status,
  };
};

export const isProcessing = (payload) => {
  return {
    type: "REGISTERATION_IS_PROCESSING",
    payload,
  };
};

export const handleuserRegInput = (e) => {
  const { value, name, type, checked } = e.target ? e.target : e;
  return {
    type: "USER_INPUT_CHANGE",
    changedField: {
      [name]: type === "checkbox" ? (checked ? "1" : "0") : value,
    },
  };
};

export const addUser =
  (userRegFormData, setErrors, setSubmitting) => (dispatch) => {
    dispatch(isProcessing(true));
    const url = base_url("register");

    Axios.post(url, userRegFormData)
      .then((res) => {
        if (res.status === 201) {
          dispatch(showModal(true));
          dispatch(addUserSuccess(res.data.new_user, res.data.message));
          dispatch(isProcessing(false));
        } else {
          dispatch(isProcessing(false));
        }
        setSubmitting(false);
      })
      .catch((error) => {
        dispatch(isProcessing(false));
        if (error.response) {
          setSubmitting(false);
          setErrors(error.response.data.errors);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Bad/No internet connection");
        }
      });
  };
