import Axios from "axios";
// import { toastr } from "react-redux-toastr";
import { allconstants } from "../constants/constants";

export const HandleMerchantTicketInput = (e) => {
  const { value, name, files } = e.target ? e.target : e;
  if (name === "comment_text") {
    return {
      type: "MERCHANT_TICKET_INPUT_CHANGE",
      changedField: { [name]: value },
      resp: true,
    };
  } else if (name === "ticket_action") {
    return {
      type: "MERCHANT_TICKET_INPUT_CHANGE",
      changedField: { action_id: value },
      ticket_action: true,
    };
  } else if (name === "ticket_comment") {
    return {
      type: "MERCHANT_TICKET_INPUT_CHANGE",
      changedField: { comment_text: value },
      ticket_action: true,
    };
  } else {
    if (typeof files === "undefined" || files === null) {
      return {
        type: "MERCHANT_TICKET_INPUT_CHANGE",
        changedField: { [name]: value },
      };
    } else {
      return {
        type: "MERCHANT_TICKET_INPUT_CHANGE",
        changedField: { file: files[0] },
      };
    }
  }
};

export const clearMerchantTicketForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_MERCHANT_TICKET_FORM" };
};
export const HandleChangeView = (status) => {
  return { type: "CHANGE_MERCHANT_TICKET_VIEW", resp: status };
};
export const clearMerchantRespForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_MERCHANT_RESP_FORM" };
};
export const clearMerchantActionForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_MERCHANT_TICKET_ACTION_FORM" };
};

export const MerchantTicketFetchSuccess = (payload, isTicket) => {
  return { type: "FETCH_MERCHANT_TICKET_SUCCESS", payload, isTicket };
};
export const MerchantTicketAddSuccess = (new_record, update = false) => {
  if (typeof new_record !== "string") {
    if (update) {
      // toastr.success("Howry!", "Ticket Record updated successfully");
    } else {
      // toastr.success("Howry!", "Ticket Record added successfully");
    }
  }

  return { type: "ADD_MERCHANT_TICKET_SUCCESS", new_record };
};
export const MerchantTicketDeleteSuccess = (record_id, resp = false) => {
  // toastr.success("Howry!", msg);
  return { type: "DELETE_MERCHANT_TICKET_SUCCESS", record_id, resp };
};
export const MerchantTicketReqError = () => {
  return { type: "MERCHANT_TICKET_ERROR" };
};
export const editMerchantTicket = (obj) => {
  return { type: "EDIT_MERCHANT_TICKET", current_merchant_ticket_obj: obj };
};
export const HandleMerchantTicketSubmit = (form_data, url_id) => (dispatch) => {
  const url = form_data.id
    ? allconstants.apiurl + "support_ticket/" + form_data.id
    : allconstants.apiurl + "merchant/" + url_id + "/support_ticket";
  const req_type = form_data.id ? Axios.put : Axios.post;
  const formData = new FormData();
  formData.append("description", form_data.description);
  formData.append("id", form_data.id);
  formData.append("ticket_type_id", form_data.ticket_type_id);
  formData.append("attached_file", form_data.file);
  form_data.id && formData.append("_method", "PUT");
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  //  const req_type = form_data.id?allconstants.axios.put:allconstants.axios.post
  req_type(url, formData, config)
    .then((response) => {
      if (response.status === 201) {
        dispatch(MerchantTicketAddSuccess(response.data));
      } else if (response.status === 200) {
        dispatch(MerchantTicketAddSuccess(response.data, true));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
  return null;
};
export const HandleSubmitMerchantTicketAction =
  (form_data, url_id, merchant_id) => (dispatch) => {
    const url =
      allconstants.apiurl +
      "support_ticket/" +
      url_id +
      "/" +
      form_data.action_id;

    const req_type = allconstants.axios.put;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200) {
          // toastr.success("OK", "Ticket status changed");
          dispatch(MerchantTicketAddSuccess(form_data.action_id));
          dispatch(HandleMerchantTicketFetch(merchant_id));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // toastr.error("Please check your network and try again");
        }
      });
    return null;
  };
export const HandleSubmitMerchantTicketResp =
  (form_data, url_id) => (dispatch) => {
    const url =
      allconstants.apiurl + "support_ticket/" + url_id + "/ticket_response";

    const req_type = form_data.id
      ? allconstants.axios.put
      : allconstants.axios.post;
    req_type(url, form_data, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 201) {
          // toastr.success("OK", "Response Sent");
          dispatch(HandleTicketResponse(response.data.support_ticket_id));
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // toastr.error("Please check your network and try again");
        }
      });
    return null;
  };
export const HandleMerchantTicketDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "support_ticket/" + record_id;

  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(MerchantTicketDeleteSuccess(record_id));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
  return null;
};
export const HandleMerchantTicketRespDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "ticket_response/" + record_id;

  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (
        response.status === 201 ||
        response.status === 200 ||
        response.status === 202
      ) {
        dispatch(
          MerchantTicketDeleteSuccess(record_id, true)
        );
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
  return null;
};
export const HandleMerchantTicketFetch = (url_id) => (dispatch) => {
  const url = allconstants.apiurl + "merchant/" + url_id + "/support_ticket";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(MerchantTicketFetchSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
  return null;
};

export const HandleTicketFetchTypes = () => (dispatch) => {
  const url = allconstants.apiurl + "ticket_type";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(MerchantTicketFetchSuccess(response.data, "ticket"));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
  return null;
};

export const HandleTicketResponse = (ticket_id) => (dispatch) => {
  const url =
    allconstants.apiurl + "support_ticket/" + ticket_id + "/ticket_response";
  allconstants.axios
    .get(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(MerchantTicketFetchSuccess(response.data, "response"));
        console.log(response);
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
  return null;
};
