import Axios from "axios";
// import { toastr } from "react-redux-toastr";
import { AxiosHeaders } from "../constants";
import { base_url } from "../constants/constants";

export const HandleCompanyInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "COMPANY_INPUT_CHANGE", changedField: { [name]: value } };
};
export const HandleCompanySwitchInput = (e) => {
  const { value } = e.target ? e.target : e;
  return { type: "COMPANY_INPUT_CHANGE", changedField: { company_id: value } };
};

export const clearCompanyForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_COMPANY_FORM" };
};

export const CompanyFetchSuccess = (payload, isCompany = false) => {
  return { type: "FETCH_COMPANY_SUCCESS", payload, isCompany };
};
export const SetKey = (payload) => {
  return { type: "SET_API_KEY", payload };
};
export const SetSingle = (company_id) => {
  return { type: "FETCH_SINGLE_COMPANY", company_id };
};
export const CompanyAddSuccess = (new_record, update = false) => {
  //if(update){}
  // toastr.success("Howry!", "Business details successfully saved");
  return { type: "ADD_COMPANY_SUCCESS", new_record };
};

export const updateBalance = (acc_balance) => {
  //this actually happens in the auth reducer
  return { type: "UPDATE_BALANCE", acc_balance };
};

export const CompanyDeleteSuccess = (msg, record_id) => {
  // toastr.success("Howry!", msg);
  return { type: "DELETE_COMPANY_SUCCESS", record_id };
};

export const CompanyReqError = (msg) => {
  // toastr.error("Whoops!", msg);
  return { type: "COMPANY_ERROR" };
};

//this state change is handled by the auth reducer
export const ActivateMerchantCompany = (payload) => {
  return { type: "UPDATE_ACTIVE_COMPANY", active_company: payload };
};

export const editCompany = (obj) => {
  return { type: "EDIT_COMPANY", current_company_obj: obj };
};

export const HandleIsCompProgress = (isProgress) => {
  return { type: "COMPANY_IS_PROGRESS", isProgress };
};

export const ActivateCompany = (id) => (dispatch) => {
  const url = base_url("company/" + id + "/setactive");
  if (id) {
    Axios.put(url).then((res) => {
      if (res.status === 200 || res.status === 201) {
        // toastr.success("Success!", "Company details updated successfully.");
        dispatch(ActivateMerchantCompany(res.data));
      } else {
        // toastr.error("Oops!", "The company details weren't changed.");
      }
    });
  }
};
export const HandleCompanySubmit = (form_data, merchant_id) => (dispatch) => {
  const url = form_data.id
    ? base_url("company/" + form_data.id)
    : base_url("merchant/" + merchant_id + "/company");
  dispatch(HandleIsCompProgress(true));
  const req_type = form_data.id ? Axios.put : Axios.post;
  req_type(url, form_data, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 201) {
        dispatch(CompanyAddSuccess(response.data));
        //dispatch(FetchSubmittedCompanyDocs(response.data));
        dispatch(HandleIsCompProgress(false));
      } else if (response.status === 200) {
        dispatch(CompanyAddSuccess(response.data, true));
        dispatch(HandleIsCompProgress(false));
      }
    })
    .catch(function (error) {
      dispatch(HandleIsCompProgress(false));
    });
  return null;
};
export const HandleCompanyDelete = (record_id) => (dispatch) => {
  const url = base_url("company/" + record_id);

  Axios.delete(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CompanyDeleteSuccess(response.data.message, record_id));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });

  return null;
};
export const GenerateKey = (record_id) => (dispatch) => {
  const url = base_url("account/apikey/" + record_id);

  Axios.put(url, { headers: AxiosHeaders })
    .then((response) => {
      if (response.status === 200) {
        dispatch(SetKey(response.data.apikey));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });

  return null;
};
export const HandleCompanyFetch =
  (request_id, isCompany = false) =>
  (dispatch) => {
    const url = isCompany
      ? base_url("company/" + request_id)
      : base_url("merchant/" + request_id + "/company");

    Axios.get(url, { headers: AxiosHeaders })
      .then((response) => {
        if (response.status === 200) {
          if (typeof response.data === "object") {
            dispatch(CompanyFetchSuccess(response.data, isCompany));
          }
          /*else{
          if(window.location.href.includes('go_live') && response.data.data.length===1){
            dispatch(CompanyFetchSuccess(response.data));
            dispatch(HandleCompanyDocumentFetch(response.data.data[0].id))
          }else{
          dispatch(CompanyFetchSuccess(response.data));
          }
        }*/
        }
      })
      .catch((failure_reason) => {
        console.error(failure_reason);
      });
    return null;
  };
