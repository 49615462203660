import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  HandleCompanySubFetch,
  HandleSubscriptionPlanFetch,
} from "../../../actions/company_sub_actions";
import { AddSubPayment } from "./sub_payment";
import { Row, Col, Card, CardBody, CardFooter } from "reactstrap";

export const CompanySubscriptionCards = ({dispatch,active_company,subscription_plan_list}) => {
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  //deleteHandler: (comp_sub_plan_id) => dispatch(HandleCompanySubDelete(comp_sub_plan_id))

  const [subplan, getSubPlan] = useState({}); //the ideal is to send this to the global state, so that we can proceed to complete the user subscription
  useEffect(() => {
    active_company.id != null &&
      dispatch(HandleCompanySubFetch(active_company.id));
    typeof subscription_plan_list !== "undefined" && (subscription_plan_list.length ||
      dispatch(HandleSubscriptionPlanFetch()));
  }, [dispatch,subscription_plan_list,active_company]);

  return (
    <>
      <Col lg={8} sm={12} md={8}>
        <Row>
          {subscription_plan_list.map((subscription_plan, key) => {
            const activePlan =
              active_company.active_plan != null &&
              subscription_plan.id ===
                active_company.active_plan.subscription_plan_id;
            return (
              <Col lg={4} md={4} sm={12} className="my-2" key={`subplan${key}`}>
                <Card
                  className="shadow bg-light"
                  style={{
                    borderRadius: "10px",
                    border: `2px solid #${activePlan ? "F87F0F" : "2580F8"}`,
                  }}
                >
                  <div className="me-1 mt-2"></div>
                  <CardBody>
                    <h5
                      className={`card-title text-center subscription-header${
                        activePlan ? "" : "-blue"
                      } text-uppercase`}
                    >
                      {subscription_plan.subscription_plan_name}
                    </h5>
                    <p className="small text-center text-muted">
                      {activePlan ? "Active" : "Subscribe"}
                    </p>
                    <hr />
                    <p className="d-flex small text-muted p-2 justify-content-center">
                      {subscription_plan.description}
                    </p>
                  </CardBody>
                  <CardFooter
                    className={`footer-color${
                      activePlan ? "" : "-blue"
                    } d-flex`}
                    style={{ borderRadius: "0px 0px 10px 10px" }}
                  >
                    <span className="text-center font-weight-bold ms-2 text-light d-flex">
                      <span className="me-1">UGX</span>
                      <span>
                        {parseInt(subscription_plan.amount).toLocaleString(
                          "en"
                        )}
                      </span>
                    </span>
                    {activePlan && (
                      <span className="ms-3 text-center font-weight-bold">
                        Current Plan
                      </span>
                    )}
                    {!activePlan && (
                      <a
                        onClick={() => [
                          getSubPlan(subscription_plan),
                          toggle(),
                        ]}
                        href
                        title="Change to this plan"
                        className="ms-3 text-center font-weight-bold text-light"
                      >
                        Subscribe
                      </a>
                    )}
                  </CardFooter>
                </Card>
              </Col>
            );
          })}
        </Row>
        <AddSubPayment
          subplan={subplan}
          activeCompany={active_company}
          dispatch={dispatch}
        />
      </Col>
    </>
  );
};
const mapCompanySubscriptionStateToProps = (state) => {
  return {
    current_company_sub_obj: state.companySubState.current_company_sub_obj,
    subscription_plan_list: state.companySubState.subscription_plan_list,
    active_company: state.authState.user_obj.active_company,
    company_sub_list: state.companySubState.company_sub_list,
  };
};

export const CompanyCardSubComp = connect(mapCompanySubscriptionStateToProps)(
  CompanySubscriptionCards
);
