import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { useSelector, useDispatch } from "react-redux";
import { HandleGroupedPaymentFetch } from "../../../actions/payment_actions";
import { HandleCollectionAggregateFetch } from "../../../actions/collection_actions";
import {
  HighchartsChart,
  Chart,
  withHighcharts,
  XAxis,
  YAxis,
  Legend,
  //ColumnSeries,
  SplineSeries,
} from "react-jsx-highcharts";
require("highcharts/modules/exporting")(Highcharts);

const DashLineChart = () => {
  const dispatch = useDispatch();
  const activeCompany = useSelector(
    (state) => state.authState.user_obj.active_company.id
  );

  useEffect(() => {
    dispatch(HandleGroupedPaymentFetch(activeCompany));
    dispatch(HandleCollectionAggregateFetch(activeCompany));
  }, [dispatch,activeCompany]);

  const groupedData = useSelector(
    (state) => state.paymentState.grouped_payment_list
  );

  const collectionData = useSelector(
    (state) => state.collectionState.grouped_collection_list
  );

  const chartLabels = [];
  const paymentData = [];
  const collectionsData = [];

  Object.keys(groupedData).forEach(function(keyName) {
    var sum = 0;
    Object.values(groupedData[keyName]).forEach(
      (x) => (sum += parseInt(x.debit))
    );
    paymentData.push(sum);
  });

  Object.keys(collectionData).forEach(function(keyName) {
    chartLabels.push(keyName);
    var sum = 0;
    Object.values(collectionData[keyName]).forEach(
      (x) => (sum += parseInt(x.credit))
    );
    collectionsData.push(sum);
  });

  return (
    <HighchartsChart>
      <Chart />

      <Legend />

      <XAxis categories={chartLabels} />

      <YAxis>
        <SplineSeries name="Payments" data={paymentData.slice(0, 5)} />
        <SplineSeries name="Collections" data={collectionsData.slice(0, 5)} />
      </YAxis>
    </HighchartsChart>
  );
};

export default withHighcharts(DashLineChart, Highcharts);
