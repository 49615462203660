import React, { useState } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GenerateKey } from "../../../actions/companyActions";
import { Row, Col, Card, Button } from "reactstrap";

export const AccountSettings = (props) => {
  const [copyBtnText, setCopyBtnText] = useState("Copy");
  return (
    <>
      <div className="content-box-header panel-heading table-container">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <span className="account-title">Account Details</span>
            <hr />
          </Col>
        </Row>

        <Row>
          <Col lg={2} sm={12}>
            {props.session_data.active_company && (
              <span className="user-name">
                <img
                  alt=""
                  className="account-setting-img img-thumbnail"
                  src="images/avatar.svg"
                />
                <Button className="account-img-container">
                  <span className="account-img-text">Edit</span>
                </Button>
              </span>
            )}
          </Col>
          {props.session_data.active_company && (
            <Col lg={7}>
              <Card className="p-4">
                <Row className="mb-2">
                  {" "}
                  <Col lg={12} sm={12} md={12}>
                    <span className="title-header">Company Name</span>
                  </Col>
                  <Col lg={12} sm={12} md={12}>
                    <span className="title-text">
                      {" "}
                      {!props.session_data.active_company ? (
                        <> choose a company </>
                      ) : (
                        <>{props.session_data.active_company.company_name}</>
                      )}{" "}
                      <span
                        style={{ marginTop: "-80px" }}
                        className="free-account-badge"
                      >
                        <b className="badge-text">
                          {props.session_data.active_company &&
                          props.session_data.active_company.active_plan
                            ? props.session_data.active_company.active_plan
                                .subscription_plan.subscription_plan_name
                            : "FREE PLAN"}
                        </b>
                      </span>{" "}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col lg={10} sm={12}>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-header ">Company RegNo</span>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-header  ">Country</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-text"> R2hdhd737373</span>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-text"> Uganda</span>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-header  mr-3">Status</span>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-header  mr-3">
                          Date Approved
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-text mr-2">
                          {!props.session_data.active_company ? (
                            <> choose a company </>
                          ) : (
                            <>{props.session_data.active_company.status_text}</>
                          )}{" "}
                          {props.session_data.active_company.status ===
                            "approved" && (
                            <img
                              className="ml-1 mb-1"
                              width="15px"
                              height="15px"
                              src="./images/check-icon.svg"
                              alt=""
                            />
                          )}{" "}
                        </span>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-text"> 21/05/2020</span>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-header  mr-3">Email</span>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-header  mr-3">Phone Number</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-text mr-3">
                          {props.session_data.email}
                        </span>
                      </Col>
                      <Col lg={6} sm={12} md={6}>
                        <span className="title-text">
                          {props.session_data.phone_number}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
        </Row>
        {props.session_data.active_company && (
          <>
            <Row className="ml-1 mt-3">
              <Col lg={12} sm={12} md={12}>
                <span className="reset-pwd">API Key </span>
              </Col>
            </Row>
            <Row className="ml-1">
              <Col>
                <hr />
              </Col>
            </Row>
            <Row className="ml-1">
              <Col sm={12} md={6} lg={6}>
                <span>Reset your API credentials</span>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    props.dispatch(
                      GenerateKey(props.session_data.active_company.id)
                    );
                  }}
                  className="reset-api float-end"
                >
                  <span className="reset-btn-text">Generate New API Key</span>
                </Button>
              </Col>
            </Row>
            {props.api_key && (
              <Row className="ml-1">
                <Col sm={12} md={6} lg={6}>
                  <span className="alert alert-success text-center">
                    {props.api_key}
                  </span>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <CopyToClipboard
                    className="btn pl-3 text-white"
                    text={props.api_key}
                    onCopy={() => setCopyBtnText("Copied")}
                  >
                    <Button className="text-whit ml-3">{copyBtnText}</Button>
                  </CopyToClipboard>
                </Col>
              </Row>
            )}
          </>
        )}
        {!props.session_data.active_company && (
          <Row className="ml-1 mt-3">
            <span className="reset-pwd">
              Activate a company or account to proceed...{" "}
            </span>
          </Row>
        )}
      </div>
    </>
  );
};

const mapAccountSettingsStateToProps = (state) => {
  return {
    session_data: state.authState.user_obj,
    api_key: state.companyState.api_key,
  };
};

export const FiredUpAccountsettings = connect(mapAccountSettingsStateToProps)(
  AccountSettings
);
