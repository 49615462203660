import React from "react";
import "../assets/styles/loader/loader.css";

const Loader = ({ isModal }) => {
  return (
    <div className={!isModal ? "flex-container" : "flex-container-modal"}>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
