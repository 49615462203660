// import { toastr } from "react-redux-toastr";
import { allconstants } from "../constants/constants";
import { HandleCompanySubSubmit } from "./company_sub_actions";

export const HandleCollectionInput = (e) => {
  const { value, name } = e.target ? e.target : e;
  return { type: "COLLECTION_INPUT_CHANGE", changedField: { [name]: value } };
};

export const clearCollectionForm = (e) => {
  e.preventDefault();
  return { type: "CLEAR_COLLECTION_FORM" };
};

export const CollectionFetchSuccess = (payload, isCollection = false) => {
  return { type: "FETCH_COLLECTION_SUCCESS", payload, isCollection };
};
export const CollectionAggregateFetchSuccess = (
  payload,
  isCollection = false
) => {
  return { type: "FETCH_AGGREGATE_COLLECTION_SUCCESS", payload, isCollection };
};

const CollectionAddSuccess = (message = "Processing Collections...") => {
  // toastr.success("Success!", message);
  return { type: "ADD_COLLECTION_SUCCESS" };
};
const CheckTransactionStatusSuccess = (message) => {
  // toastr.success("Ok!", message);
  return { type: "CHECK_TRANSACTION_SUCCESS" };
};
const CheckTransactionStatusFailed = (message) => {
  // toastr.warning("Failure!", message);
  return { type: "CHECK_TRANSACTION_FAILED" };
};
const CollectionDeleteSuccess = (msg, record_id) => {
  // toastr.success("Howry!", msg);
  return { type: "DELETE_COLLECTION_SUCCESS", record_id };
};
const CollectionReqError = (msg) => {
  // toastr.error("Whoops!", msg);
  return { type: "COLLECTION_ERROR" };
};
export const editCollection = (obj) => {
  return { type: "EDIT_COLLECTION", current_collection_obj: obj };
};
export const InitCollection = (step) => {
  return { type: "INIT_COLLECTION", step };
};
let intVlId,
  nTimes = 0;
export const HandleCollectionSubmit =
  (form_data, company_id, subscription_plan_id = null) =>
  (dispatch) => {
    if (form_data.amount.match(/^[0-9]+$/) == null) {
      // toastr.warning("Warning", "The input must be a number");
    } else {
      var phone = form_data.masked_contact.replace(/\D+/g, "");
      const url =
        allconstants.apiurl + "company/" + company_id + "/transcn/collctn";

      dispatch(InitCollection(2));
      allconstants.axios
        .post(
          url,
          { ...form_data, msisdn: "+" + phone },
          { headers: allconstants.headers }
        )
        .then((response) => {
          if (response.status === 200) {
            //the request was immediatetely approved by the client, lets basically dispatch the success message and repopulate the table with the new entry
            dispatch(CollectionAddSuccess(response.data.message));
            dispatch(InitCollection(3));
          } else if (response.status === 201) {
            //pending approval/authorization by the customer
            if (
              response.data.transaction !== "undefined" &&
              response.data.transaction.status === 2
            ) {
              //can we try for, say, 6 times for every 20 seconds, to check for the status of the transaction
              // nTimes = 1;
              intVlId = setInterval(
                () =>
                  dispatch(
                    CheckTransactionStatus(
                      response.data.transaction.request_ref,
                      { subscription_plan_id },
                      company_id
                    )
                  ),
                20000
              );
            }
          } else if (response.status === 202) {
            //this transaction just failed, we need to give feedback
            if (
              response.data.transaction !== "undefined" &&
              response.data.message !== "undefined"
            ) {
              dispatch(CheckTransactionStatusFailed(response.data.message));
            }
            dispatch(InitCollection(4));
          } else {
            //network error, PG network issues, we have to give appropriate feedback
            dispatch(
              CollectionReqError(
                response.data.message !== "undefined"
                  ? response.data.message
                  : "Transaction failed"
              )
            );
            dispatch(InitCollection(5));
          }
        })
        .catch((error) => {
          console.error(error);

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            dispatch(CollectionReqError(error.response.data.message));
            console.error(error.response.data);
            console.error(error.response.status, error.response.statusText);
            //console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          dispatch(CollectionReqError("Network/Server failure. Try again"));
          dispatch(InitCollection(5));
        });
    }
    return null;
  };

export const HandleCollectionDelete = (record_id) => (dispatch) => {
  const url = allconstants.apiurl + "collection/" + record_id;
  allconstants.axios
    .delete(url, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 201 || response.status === 200) {
        dispatch(CollectionDeleteSuccess(response.data.message, record_id));
      }
    })
    .catch((error) => {
      console.error(error);
      dispatch(CollectionReqError("Network/Server failure. Try again"));
    });
};

export const HandleCollectionAggregateFetch =
  (company_id, params = {}) =>
  (dispatch) => {
    allconstants.axios
      .get(
        allconstants.apiurl + "company/" + company_id + "/aggtransac/collctn",
        {
          headers: allconstants.headers,
          params,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch(CollectionAggregateFetchSuccess(response.data));
        }
      })
      .catch((failure_reason) => {
        console.error(failure_reason);
      });
    return null;
  };

export const CheckTransactionStatus =
  (ref_no, subscription_data = {}, company_id = null) =>
  (dispatch) => {
    //find the status of a transaction
    const status_url =
      allconstants.apiurl + "transcn/collctn/status/" + ref_no + "";
    //if it is as a result of the timed interval requests
    if (typeof nTimes !== "undefined" && typeof intVlId !== "undefined") {
      if (nTimes === 6) {
        clearInterval(intVlId);
      }
      nTimes++;
    }

    allconstants.axios
      .get(status_url, { headers: allconstants.headers })
      .then((response) => {
        if (response.status === 200) {
          if (
            /* typeof nTimes !== 'undefined' && */ typeof intVlId !==
            "undefined"
          ) {
            clearInterval(intVlId); //clear the interval as it had been defined
            nTimes = null;
          }
          dispatch(CheckTransactionStatusSuccess(response.data.message));
          dispatch(HandleCompanySubSubmit(subscription_data, company_id));
          !subscription_data.subscription_plan_id &&
            dispatch(InitCollection(3)); //because we do not have to subscribe, we can put a stop to the transaction processing
        } else if (response.status === 201) {
          //pending approval/authorization by the customer
          //if (response.data.request !== "undefined" && response.data.request.status === 2 ) {
          dispatch(CheckTransactionStatusSuccess(response.data.message)); //recursive function calls
          //}
        } else if (response.status === 202) {
          //this transaction just failed, we need to give feedback
          if (
            response.data.request !== "undefined" &&
            response.data.message !== "undefined"
          ) {
            if (
              /* typeof nTimes !== 'undefined' && */ typeof intVlId !==
              "undefined"
            ) {
              clearInterval(intVlId); //clear the interval as it had earlier been defined
              nTimes = null;
            }
            dispatch(CheckTransactionStatusFailed(response.data));
            dispatch(InitCollection(3));
          }
        } else {
          //network error, PG network issues, we have to give appropriate feedback
          dispatch(
            CollectionReqError(response.data.message || "Transaction failed")
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // toastr.error("Please check your network and try again");
        }
      });
  };

export const handleApprovalStatusChange = (form_data) => (dispatch) => {
  const url =
    allconstants.apiurl +
    `collection/${form_data.id}/action/${form_data.status}`;
  const data = {
    reason_for_decision: form_data.reason_for_decision,
  };
  allconstants.axios
    .post(url, data, { headers: allconstants.headers })
    .then((response) => {
      if (response.status === 200) {
        dispatch(CollectionAddSuccess(response.data, true));
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // toastr.error("Please check your network and try again");
      }
    });
};
