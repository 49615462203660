//password strength validation

export function PasswordStrengthChecker(passwordParameter) {
  const upperCase = new RegExp("(?=.*[A-Z])");
  const lowerCase = new RegExp("(?=.*[a-z])");
  const oneDigit = new RegExp("(?=.*[0-9])"); //atleast one digit
  const specialChar = new RegExp("(?=.*[^A-Za-z0-9])"); //atleast one special characters

  const maxPossiblePoints = 5.0;
  let passwordStrength = 0;
  const charLen = passwordParameter.toString().length;
  const passRules = {
    ucase: upperCase.test(passwordParameter),
    lcase: lowerCase.test(passwordParameter),
    digits: oneDigit.test(passwordParameter),
    specialChar: specialChar.test(passwordParameter),
    charLen: charLen > 7 ? 1 : 0,
    strength: 0,
  };
  passwordStrength += passRules.ucase ? 1 : 0; //add points for uppercase
  passwordStrength += passRules.lcase ? 1 : 0; //one points for lowercase
  passwordStrength += passRules.digits ? 1 : 0; //one point for numbers respectively
  passwordStrength += passRules.specialChar ? 1 : 0; //one point for special chars respectively
  passwordStrength += charLen > 7 ? 1 : 0;
  passRules.strength = Math.floor((passwordStrength * 100) / maxPossiblePoints);
  return passRules;
}

//check if an element is in array
export function checkInArray($array, $find, search_key) {
  let found_item = findInArray($array, $find, search_key);
  return typeof found_item !== "undefined";
}
//check if an element is in array
export function findInArray($array, $find, search_key) {
  return $array.find(($element) => {
    return (
      $find ===
      (typeof search_key === "undefined" ? $element : $element[search_key])
    );
  });
}

//function to format currencies
export function currFormat(n) {
  let formatted = "";
  formatted =
    n < 0 ? "(" + numberWithCommas(n * -1) + ")" : numberWithCommas(n);
  return formatted;
}

//function for rounding off currecies
export function roundOff(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

//The function for making currency have commas
export function numberWithCommas(x) {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
//add commas to a number approriately
export const numberFormat = (value) => {
  value = value.toString().replace(/,/g, "");
  //alert(numberWithCommas(value));
  return numberWithCommas(value);
};

//format time
export const timeFormat = (value) => {
  if (value) {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/;
    const [match] = regex.exec(value);
    return match;
  }
  return value;
};
