const CompanyContactsState = {
   id: '', 
   contact_type_id: '', 
   contact_details: '',
   masked_contact:''
}
const CompanyContactsInitState = {
   company_contact_list: [],
   current_company_contact_obj: CompanyContactsState,
}

export const CompanyContactsReducer = (prevState = CompanyContactsInitState, action) => {
   const form_state = prevState.current_company_contact_obj
   let new_formState
   switch (action.type) {
      case 'COMPANY_CONTACT_ERROR':
         return { ...prevState };
      case 'FETCH_COMPANY_CONTACT_SUCCESS':
         return { ...prevState, company_contact_list: action.payload.data }
      case 'COMPANY_CONTACT_INPUT_CHANGE':
         new_formState = { ...form_state, ...action.changedField }
         return { ...prevState, current_company_contact_obj: new_formState };
      case 'CLEAR_COMPANY_CONTACT_FORM':
         return { ...prevState, current_company_contact_obj: CompanyContactsState };
      case 'EDIT_COMPANY_CONTACT':
         new_formState = { ...action.current_obj }
         return { ...prevState, current_company_contact_obj: new_formState };
      case 'DELETE_COMPANY_CONTACT_SUCCESS':
         let new_list = prevState.company_contact_list.filter(current_rec => current_rec.id !== action.record_id)
         return { ...prevState, company_contact_list: new_list };
      case 'ADD_COMPANY_CONTACT_SUCCESS':
         let company_contact_new = true
         let company_contact_list = prevState.company_contact_list.map(company_contact => {
            if (company_contact.id === action.new_record.id) {
               company_contact_new = false
               return action.new_record
            } else {
               return company_contact
            }
         }
         )
         company_contact_new && company_contact_list.push(action.new_record)
         const new_state = { ...prevState, company_contact_list }
         if (company_contact_new) {
            new_state.current_company_contact_obj = CompanyContactsState
         }
         return new_state;
      default:
         return prevState;
   }
}