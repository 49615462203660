const CompanyDocumentsState = {
id: '', 
document_type_id:'',
doc_file:''
}
const CompanyDocumentsInitState = {
   company_document_list: [],
   current_company_document_obj: CompanyDocumentsState,
   curent_preview_document:'',
   submitted_company:'',
   isProgress:false

}

export const CompanyDocumentsReducer = (prevState = CompanyDocumentsInitState, action) => {
   const form_state = prevState.current_company_document_obj
   let new_formState
   switch (action.type) {
      case 'COMPANY_DOCUMENT_ERROR':
         return { ...prevState };
      case 'FETCH_COMPANY_DOCUMENT_SUCCESS':
         return { ...prevState, company_document_list: action.payload.data }
      case 'SUBMITTED_COMPANY_OBJ':
         localStorage.setItem('sub_comp_id',action.payload.id)
         return { ...prevState, submitted_company: action.payload }
      case 'COMPANY_DOCUMENT_INPUT_CHANGE':
         new_formState = { ...form_state, ...action.changedField }
         return { ...prevState, current_company_document_obj: new_formState };
      case 'TYPE_ID':
         new_formState = { ...form_state, ...{document_type_id:action.id} }
         return { ...prevState, current_company_document_obj: new_formState };
      case 'CLEAR_COMPANY_DOCUMENT_FORM':
         return { ...prevState, current_company_document_obj: CompanyDocumentsState,submitted_company:''};
      case 'EDIT_COMPANY_DOCUMENT':
         new_formState =  { ...action.current_obj }
         return { ...prevState, current_company_document_obj: new_formState };
      case 'DELETE_COMPANY_DOCUMENT_SUCCESS':
         let new_list = prevState.company_document_list.filter(current_rec => current_rec.id !== action.record_id)
         return { ...prevState, company_document_list: new_list };
      case 'PREVIEW_COMPANY_DOCUMENT':
         return {...prevState,curent_preview_document : action.doc_data}
      case 'COMPANY_IS_PROGRESS':
         return {...prevState,isProgress : action.isProgress}
      
      case 'ADD_COMPANY_DOCUMENT_SUCCESS':
         let company_document_new = true
         let company_document_list = prevState.company_document_list.map(company_document => {
            if (company_document.id === action.new_record.id) {
               company_document_new = false
               return action.new_record
            } else {
               return company_document
            }
         }
         )
         company_document_new && company_document_list.push(action.new_record)
         const new_state = { ...prevState, company_document_list }
         if (company_document_new) {
            new_state.current_company_document_obj = CompanyDocumentsState
         }
         return new_state;
      default:
         return prevState;
   } 
   
}