const CompanyFormState = {
  id: "",
  company_name: "",
  company_type: "",
  description: "",
  no_of_employees: "",
  reg_no: "",
  address: "",
  status: "new",
  reason_for_decision: "",
  country_id: "",
};
const CompanyInitState = {
  company_list: [],
  single_record: {},
  companyChanged: false,
  current_company_obj: CompanyFormState,
  api_key: "",
  isCompProgress: false,
};

export const CompanyReducer = (prevState = CompanyInitState, action) => {
  const form_state = prevState.current_company_obj;
  let new_formState;
  switch (action.type) {
    case "COMPANY_ERROR":
      return { ...prevState };
    case "SET_API_KEY":
      return { ...prevState, api_key: action.payload };
    case "FETCH_COMPANY_SUCCESS":
      if (action.isCompany) {
        return { ...prevState, single_record: action.payload };
      } else {
        return { ...prevState, company_list: action.payload.data };
      }
    case "FETCH_SINGLE_COMPANY":
      let single_obj = prevState.company_list.find(
        (current_rec) =>
          parseInt(current_rec.id) === parseInt(action.company_id)
      );
      return { ...prevState, single_record: typeof single_obj !== 'undefined'?single_obj:{} };
    case "COMPANY_INPUT_CHANGE":
      if (action.changedField.company_id) {
        return { ...prevState, companyChanged: true };
      }
      new_formState = { ...form_state, ...action.changedField };
      return { ...prevState, current_company_obj: new_formState };
    case "RESET_COMPANY":
      return { ...prevState, companyChanged: false };
    case "CLEAR_COMPANY_FORM":
      return { ...prevState, current_company_obj: CompanyFormState };
    case "EDIT_COMPANY":
      new_formState = { ...action.current_company_obj };
      return { ...prevState, current_company_obj: new_formState };
    case "DELETE_COMPANY_SUCCESS":
      let new_list = prevState.company_list.filter(
        (current_rec) => current_rec.id !== action.record_id
      );
      return { ...prevState, company_list: new_list };
    case "ADD_COMPANY_SUCCESS":
      let company_new = true;
      let company_list = prevState.company_list.map((company) => {
        if (company.id === action.new_record.id) {
          company_new = false;
          return action.new_record;
        } else {
          return company;
        }
      });
      company_new && company_list.push(action.new_record);
      const new_state = { ...prevState, company_list };
      if (company_new) {
        new_state.current_company_obj = action.new_record;
      }
      return new_state;
    case "COMPANY_IS_PROGRESS":
      return { ...prevState, isCompProgress: action.isProgress };
    default:
      return prevState;
  }
};
