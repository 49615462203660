import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addUser } from "../../actions/userRegister";
import MailSent from "./mailSent";
//import { validateInput } from '../../constants'
import { ResendEmail } from "../../actions/authAction";
import { PasswordStrengthChecker } from "../../constants/GenHelpers";
// import styles from "../../assets/css/signUp.module.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField } from "./TextField";
import AuthFooter from "./authFooter";
import * as Yup from "yup";
import { Row, Col, Button, Container } from "reactstrap";
import WelcomeText from "./welcomeText";

const LoginBox = ({ dispatch, is_loading, formData }) => {
  const formikRef = React.useRef();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [_firstname, setFirstname] = useState("");
  const [_lastname, setLastname] = useState("");
  const PHONE_REGEX = /^\+?\d{1,4}[0-9]{8,11}$/;
  const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?).{8,}$/;

  const validate = Yup.object({
    firstname: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Firstname is required"),
    phone_number: Yup.string()
      .min(10, "Must be atleast 10 characters")
      .matches(PHONE_REGEX, "Please Enter a valid Telephone number")
      .required("Phone number is required"),
    lastname: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Lastname is required"),
    gender: Yup.string().required("Gender is required"),
    tcs_accepted: Yup.string().required("Required"),
    address: Yup.string().required("Address is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 charaters")
      .matches(PASSWORD_REGEX, "Please Enter a strong password")
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password confirmation is required"),
  });

  const handleFistname = (e) => {
    setFirstname(e.target.value.replace(/[^\w\s]/gi, ""));
  };

  const handleLastname = (e) => {
    setLastname(e.target.value.replace(/[^\w\s]/gi, ""));
  };

  return (
    <div className="login-wrapper  mx-auto">
      <div className="content-wrap">
        <Formik
          enableReinitialize
          initialValues={{
            firstname: _firstname,
            phone_number: "",
            lastname: _lastname,
            email: "",
            password: "",
            password_confirmation: "",
            address: "",
            tcs_accepted: "",
            gender: "",
          }}
          validationSchema={validate}
          validateOnChange={validateAfterSubmit}
          validateOnBlur={true}
          inneRef={formikRef}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            setValidateAfterSubmit(true);
            dispatch(addUser(values, setErrors, setSubmitting));
          }}
        >
          {(formik) => (
            <div>
              <Form className="border border-light p-3">
                <Row className="mb-1">
                  <Col lg={6} sm={12} md={6}>
                    <TextField
                      name="firstname"
                      type="text"
                      value={formik.values.firstname}
                      placeholder="FirstName"
                      onKeyUp={handleFistname}
                    />
                  </Col>
                  <Col lg={6} sm={12} md={6}>
                    <TextField
                      name="lastname"
                      type="text"
                      value={formik.values.lastname}
                      placeholder="LastName"
                      onKeyUp={handleLastname}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12} md={6}>
                    <Field
                      as="select"
                      name="gender"
                      className={`custom-select form-control`}
                    >
                      <option>Gender</option>
                      <option value="0">Male</option>
                      <option value="1">Female</option>
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="gender"
                      className="error"
                    />
                  </Col>
                  <Col lg={6} sm={12} md={6}>
                    <TextField
                      name="address"
                      type="text"
                      placeholder="PLOT 0012 ABC STREET"
                    />
                  </Col>
                </Row>
                <TextField
                  name="phone_number"
                  type="text"
                  placeholder="+256 772 123456"
                />

                <TextField name="email" type="email" placeholder="E-mail" />

                <TextField
                  label="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                />
                <TextField
                  label="Confirm Password"
                  name="password_confirmation"
                  type="password"
                  placeholder="ConfirmPassword"
                />
                {formik.values.password !== "" && (
                  <div className="progress mb-2">
                    <div
                      className={`progress-bar bg-${
                        PasswordStrengthChecker(formik.values.password)
                          .strength > 74
                          ? "info "
                          : PasswordStrengthChecker(formik.values.password)
                              .strength > 49
                          ? "warning"
                          : "danger"
                      }`}
                      role="progressbar"
                      style={{
                        width:
                          PasswordStrengthChecker(formik.values.password)
                            .strength + "%",
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                )}
                {formik.values.password !== "" &&
                  PasswordStrengthChecker(formik.values.password).strength <
                    75 && (
                    <span className="text-danger">
                      Password must contain at least{" "}
                      {!PasswordStrengthChecker(formik.values.password)
                        .charLen && "8 characters,"}
                      {!PasswordStrengthChecker(formik.values.password).ucase &&
                        " an uppercase letter,"}
                      {!PasswordStrengthChecker(formik.values.password).lcase &&
                        " a lower case letter,"}
                      {!PasswordStrengthChecker(formik.values.password)
                        .digits && " a number"}{" "}
                      {!PasswordStrengthChecker(formik.values.password)
                        .specialChar && ", a special character"}
                      !
                    </span>
                  )}
                <label>
                  <Field
                    name="tcs_accepted"
                    className="me-2 leading-tight"
                    type="checkbox"
                  />
                  <span className="tcs_accepted_text">
                    I acknowledge that I have read and do hereby confirm that I
                    am in agreement with the{" "}
                    <Link to="/terms_of_use">Terms of Use</Link>,
                    <Link to="/merchant_sla">&nbsp;Merchant Agreement</Link>
                    &nbsp;and <Link to="/privacy_policy">
                      Privacy Policy
                    </Link>.{" "}
                  </span>
                </label>

                {is_loading === true ? (
                  <Button className="sign-in-btn my-1 shadow-none" block>
                    <div class="spinner-grow" role="status"></div>
                    Proccessing ...
                  </Button>
                ) : (
                  <Button
                    disabled={
                      formik.values.tcs_accepted === true ? false : true
                    }
                    data-test-id="submit_register"
                    className="sign-in-btn my-1 shadow-none"
                    block
                    type="submit"
                  >
                    Sign Up
                  </Button>
                )}
                <div className="text-center">
                  <div className="already">
                    <p className="sign-up-text">Already have an account?</p>
                    <Link to="/login" className="sign-up-text">
                      Login
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
const SignUp = (props) => {
  /*const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      height                : 'auto',
      width                 : '30vw',
      transform             : 'translate(-50%, -50%)'
    }
  };*/

  return (
    <>
      <Container fluid style={{ height: "100vh" }}>
        <Row>
          <Col lg={9} md={9} sm={12} className="mobile_view">
            <img
              width="800px"
              height="200px"
              src="./images/login-svg.png"
              alt=""
            />
          </Col>
          <Col lg={3} md={3} sm={12}>
            <nav className="navbar navbar-expand-lg navbar-light">
              <Button
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </Button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto">
                  <li className="nav-item active">
                    <Link to="/login" className="nav-link login-nav-btn">
                      Login
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link login-nav-btn" href="/#">
                      FAQ
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link login-nav-btn" href="/#">
                      Help &amp; Contact
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle login-nav-btn"
                      href="/#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      ENG
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </Col>
        </Row>
        <Row>
          <Col lg={7} md={6} sm={12} className="pt-5 px-5 text-center">
            <WelcomeText
              welcome="Get Started"
              description="This is a secure system, you will need to provide"
              shortDescription="all the needed details before you proceed."
            />
          </Col>
          <Col lg={5} md={5} sm={12}>
            {props.redirectToMailSent ? (
              <>
                <MailSent type="sign_up" />
                <Button
                  onclick={(e) => props.dispatch(ResendEmail())}
                  className="sign-in-btn btn-sm d-block my-2 text-light"
                >
                  Did'nt recieve the email
                </Button>
              </>
            ) : (
              <LoginBox {...props} redirectToMailSent={null} />
            )}
          </Col>
        </Row>
        <div className="login-footnote">
          <AuthFooter />
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formData: state.userRegState.currentFormObj,
    redirectToMailSent: state.userRegState.redirectToMailSent,
    is_loading: state.userRegState.is_loading,
  };
};
export const RegisterUser = connect(mapStateToProps)(SignUp);

export default RegisterUser;
