import React from "react";
import { Link } from "react-router-dom";
import { HandleCompanySubSubmit } from "../../../actions/company_sub_actions";
import {
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
} from "reactstrap";

export const AddSubPayment = (props) => {
  const { dispatch, modal, toggle } = props;

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span className="modal-header-text">Change Plan</span>
        </ModalHeader>
        <ModalBody>
          <div className="container-fluid mt-1">
            <p>Confirm change of plan:</p>
            <Row className="my-2">
              <span className="upgrade-2">From (Current Plan): </span>{" "}
              <span className="upgrade-1 ms-1">
                {props.activeCompany.active_plan
                  ? props.activeCompany.active_plan.subscription_plan_name
                  : "None"}
              </span>
            </Row>
            <Row className="my-2">
              <span className="upgrade-2">To (New Plan): </span>{" "}
              <span className="upgrade-1 ms-1">
                {" "}
                {props.subplan.subscription_plan_name}
              </span>
            </Row>
            {props.activeCompany.acc_balance &&
              props.subplan &&
              parseInt(props.activeCompany.acc_balance) >=
                parseInt(props.subplan.amount) && (
                <>
                  <div className="row">
                    <span className="upgrade-2">
                      UGX {parseInt(props.subplan.amount).toLocaleString("en")}{" "}
                      will be deducted from your wallet.
                    </span>
                  </div>
                  <span className="lead graph-text-active">
                    Wallet Balance:
                  </span>
                  <b>
                    (UGX)
                    {props.activeCompany.acc_balance &&
                      parseInt(props.activeCompany.acc_balance).toLocaleString(
                        "en"
                      )}
                  </b>
                </>
              )}
            {(!props.activeCompany ||
              (props.activeCompany.acc_balance &&
                Object.keys(props.subplan).length > 0)) &&
              parseInt(props.activeCompany.acc_balance) <
                parseInt(props.subplan.amount) && (
                <>
                  <span className="d-flex justify-content-center lead">
                    Your wallet balance is low, please first top up your
                    account.
                  </span>
                  <Row className="d-flex justify-content-center my-2 pt-2">
                    <Link
                      className="btn btn-sm"
                      to={{
                        pathname: "/transactions/collections",
                        state: {
                          subPlanId: props.subplan.id,
                          subPlanAmount: props.subplan.amount,
                        },
                      }}
                      title="Click to top up your account"
                    >
                      {" "}
                      <img
                        src="https://www.flaticon.com/premium-icon/icons/svg/1992/1992082.svg"
                        alt="Top up icon"
                        width="100px"
                        height="100px"
                      />
                    </Link>
                  </Row>
                </>
              )}
          </div>
        </ModalBody>
        {props.activeCompany.acc_balance &&
          props.subplan &&
          parseInt(props.activeCompany.acc_balance) >=
            parseInt(props.subplan.amount) && (
            <ModalFooter>
              <Button
                data-dismiss="modal"
                className="clear-close-text text-white"
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(
                    HandleCompanySubSubmit(
                      { subscription_plan_id: props.subplan.id },
                      props.company_id
                    )
                  );
                }}
                className="pay-btn text-white"
              >
                <span className="pay-btn-text">Subscribe</span>
              </Button>
            </ModalFooter>
          )}
      </Modal>
    </>
  );
};
