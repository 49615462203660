import React from "react";
import { useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
} from "reactstrap";
import TblComp from "../../../datatable";
import conversation from "../../../assets/img/conversation.svg";
import { ReduxTicketSupport } from "./support_response";
import {
  clearMerchantTicketForm,
  editMerchantTicket,
  HandleChangeView,
} from "../../../actions/merchant_support_ticket_action";

export const MerchantTicketTable = ({merchant_ticket_list,ticket_types,response,toggle}) => {
  const dispatch = useDispatch();
  const [dropDownState, setDropDownState] = React.useState(false);

  const [rowIdx, setRowIdx] = React.useState("xyz");
  const toggleDdState = (ticketId) => { setRowIdx(ticketId); setDropDownState(!dropDownState); };

  const TicketTypes = (ticket) => {
    if (typeof ticket_types !== "undefined") {
      const ticketType = ticket_types.find(
        (ticket_type) => ticket_type.id === ticket.ticket_type_id
      );
      if(ticketType !=="undefined"){
        return ticketType.ticket_type_name;
      }
    }
    return "New ticket";
  };
  return response ? (
    <ReduxTicketSupport/>
  ) : (
    <>
      <Button
        className="payment-btn my-3 text-white"
        onClick={(e) => [dispatch(clearMerchantTicketForm(e)), toggle()]}
      >
        New Ticket{" "}
      </Button>
      <TblComp
        columns={[
          {
            id: "ticket_name", // Required because our accessor is not a string
            Header: "Category",
            maxWidth: 100,
            accessor: (full) => TicketTypes(full),
          },
          { Header: "Details", accessor: "description", filter: "fuzzyText" },
          { Header: "Status", accessor: "status_text", filter: "fuzzyText" },
          {
            Header: "Date Opened",
            accessor: "date_created",
            filter: "fuzzyText",
          },
          {
            id: "actions", // Required because our accessor is not a string
            Header: "Actions",
            maxWidth: 100,
            accessor: (full) => (
              <Dropdown
                isOpen={rowIdx === full.id && dropDownState}
                toggle={toggleDdState}
              >
                <DropdownToggle size="sm" outline>
                  {/* <MoreVert
                  className="pointer-cursor"
                  onClick={(e) => {
                    setRowIdx(full.id);
                  }}
                /> */}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    title="Respond"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(editMerchantTicket(full));
                      dispatch(HandleChangeView(true));
                    }}
                  >
                    <img
                      src={conversation}
                      height="15px"
                      width="15px"
                      alt="failed to load"
                      className="ms-1"
                    />
                  </DropdownItem>
                  <DropdownItem onClick={(e) => {e.preventDefault(); dispatch(editMerchantTicket(full))}}>
                    Edit
                  </DropdownItem>
                  <DropdownItem
                  // onClick={() =>
                  //   toastr.confirm(
                  //     "Caution!!, Are you sure??",
                  //     {
                  //       onOk: () => dispatch(HandleMerchantTicketDelete(full.id),
                  //       onCancel: () => console.info("Ticket Deletion Cancelled"),
                  //       okText: "Delete",
                  //       cancelText: "Cancel",
                  //     }
                  //   )
                  // }
                  >
                    {/* <Delete style={{ color: "red" }} fontSize="small" /> */}
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ),
          },
        ]}
        data={merchant_ticket_list}
      />
    </>
  );
};
