import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Input,
  FormGroup,
} from "reactstrap";
import { HandleMerchantTicketSubmit } from "../../../actions/merchant_support_ticket_action";

export const AddMerchantTicket = ({
    current_merchant_ticket_obj: formState,
    ticket_types,
    merchant,
    toggle,
    modal,
    dispatch
  }) => {
  let url_id = typeof merchant !== "undefined" && merchant.id;

  const validate = Yup.object({
    description: Yup.string().required("Description is required"),
    ticket_type_id: Yup.string().required("Ticket_type_id is required"),
    file: Yup.string().required("File is required"),
  });

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span className="modal-header-text">Merchant Ticket</span>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              description: "",
              ticket_type_id: "",
              file: "",
            }}
            validationSchema={validate}
            onSubmit={(values) => {
              dispatch(HandleMerchantTicketSubmit(values, url_id));
            }}
          >
            {(formik) => (
              <Form>
                <Row>
                  <div className="container-fluid">
                    <FormGroup>
                      <Row className="mt-3">
                        <Col lg={12} md={12} sm={12} className="mb-3">
                          <label className="label-text">
                            Select a Ticket Category
                          </label>
                          <Field
                            as="select"
                            name="ticket_type_id"
                            className={`custom-select form-control ${
                              formik.errors.ticket_type_id
                                ? `is-invalid mb-2`
                                : ""
                            }`}
                          >
                            <option>Choose Ticket Category</option>
                            {ticket_types.map((ticket) => (
                              <option value={ticket.id} key={ticket.id}>
                                {ticket.ticket_type_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name="ticket_type_id"
                            className="error"
                          />
                        </Col>
                        <Col lg={12} md={12} sm={12} className="mb-3">
                          <Field
                            as="textarea"
                            name="description"
                            className={`custom-select form-control ${
                              formik.errors.description ? `is-invalid` : ""
                            }`}
                          ></Field>
                          <ErrorMessage
                            component="div"
                            name="description"
                            className="error mb-2"
                          />
                        </Col>
                        <Col lg={12} md={12} sm={12} className="my-2">
                          <Input
                            name="file"
                            type="file"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "file",
                                event.currentTarget.files[0]
                              );
                            }}
                            className="form-control"
                          />

                          {formState.id && (
                            <strong className="text-danger">
                              *Your currently saved document will be deleted on
                              uploading another one{" "}
                            </strong>
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                </Row>

                <Button
                  type="submit"
                  className="pay-btn shadow-none"
                  size="sm"
                  block
                >
                  <span className="pay-btn-text">Submit Ticket</span>
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};
