import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import { AuthReducer } from "../reducers/authReducer";
import { userReducer } from "../reducers/userRegReducer";
import { CompanyReducer } from "../reducers/companyReducer";
import { MerchantTicketsReducer } from "../reducers/merchantSupportReducer";
import { PaymentReducer } from "../reducers/paymentReducer";
import { CompanyContactsReducer } from "../reducers/CompanyContactReducer";
import { CompanyDocumentsReducer } from "../reducers/CompanyDocumentReducer";
import { ContactTypeReducer } from "../reducers/types/contactTypeReducer";
import { DocumentTypeReducer } from "../reducers/types/documentTypeReducer";
import { CollectionReducer } from "../reducers/collectionReducer";
import { ServiceProviderReducer } from "../reducers/serviceproviderReducer";
import { CurrencyReducer } from "../reducers/currencyReducer";
import { CompanySubReducer } from "../reducers/companySubReducer";
import { PayReqReducer } from "../reducers/paymentReqs";
import { CountryReducer } from "../reducers/CountryReducer";
import { LiquidationReducer } from "../reducers/liquidationReducer";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    userRegState: userReducer,
    companyDocumentState: CompanyDocumentsReducer,
    companyContactsState: CompanyContactsReducer,
    contactTypeState: ContactTypeReducer,
    PayReqState: PayReqReducer,
    companyState: CompanyReducer,
    collectionState: CollectionReducer,
    merchantTicketState: MerchantTicketsReducer,
    authState: AuthReducer,
    paymentState: PaymentReducer,
    documenttypereducer: DocumentTypeReducer,
    serviceprovider: ServiceProviderReducer,
    companySubState: CompanySubReducer,
    currencyState: CurrencyReducer,
    CountryState: CountryReducer,
    LiquidationState: LiquidationReducer,
  },
});
